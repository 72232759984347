import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../context/context';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { PanelRightOpen } from 'lucide-react';

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { toggleButton, SetToggleButton } = useContext(context);

  const [showSubMenu, setShowSubMenu] = useState(false);
  const showsubmenu = () => {
    return location.pathname === "/business-details" ||
      location.pathname === "/contact-details" ||
      location.pathname === "/location-and-maps" ||
      location.pathname === "/faqs" ||
      location.pathname === "/pricing-and-deals" ||
      location.pathname === "/photos-and-videos" ||
      location.pathname === "/social-network" ||
      location.pathname === "/shadi_tayari-badge" ||
      location.pathname === "/preferred-suppliers" ||
      location.pathname === "/additional-details";
  };

  useEffect(() => {
    setShowSubMenu(showsubmenu());
  }, [location.pathname]);



  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  const closeSubMenu = () => {
    setShowSubMenu(false);
  };

  useEffect(() => {
    const sidebar = document.querySelector('.sidebar');
    const body = document.querySelector('body');
    const sidebarnav = document.querySelector('.sidebar .over-flow-scroll');
  
    const handleSidebarClassChange = () => {
      if (sidebar.classList.contains('active')) {
        if (window.innerWidth <= 767) {
          body.classList.add('overflow-hidden');
          sidebarnav.classList.add('overflow-scroll');
        }
      } else {
        if (window.innerWidth <= 767) {
        body.classList.remove('overflow-hidden');
        sidebarnav.classList.remove('overflow-scroll');
      }
    }
    };
  
    // Attach event listener to monitor changes in sidebar class
    const observer = new MutationObserver(handleSidebarClassChange);
    observer.observe(sidebar, { attributes: true });
  
    // Cleanup function
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="wf-business-profile-sidebar-toggle" onClick={() => SetToggleButton((prev) => !prev)}>
        <PanelRightOpen />
      </div>
      <div className={toggleButton ? "sidebar wf-shadow-2 white-bg close rounded active" : "sidebar wf-shadow-2 white-bg rounded"}>
      
        <div class="logo-details cursor-pointer" onClick={() => {
          SetToggleButton((prev) => !prev)
          if (showSubMenu) {
            closeSubMenu();
          }
        }}>
          <i class="bi bi-list d-md-block d-none"></i>
          <i class="bi bi-x-lg d-block d-md-none"></i>
        </div>
        <div className='over-flow-scroll h-100'>
        <ul class="nav-links">
          <li>
            <div className={location?.pathname == "/dashboard" ? "icon-link active" : "icon-link"}>
              <Link to="/dashboard" >
                <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/dashboard-icon-v.svg`} alt="" className="sidebar-icon-v" /></div>
                <span class="link_name">{t('Dashboard')}</span>
              </Link>
            </div>
          </li>
          <li>
            <div className={location?.pathname == "/analytics" ? "icon-link active" : "icon-link"}>
              <Link to="/analytics" >
                <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/analytics-icon-v.svg`} alt="" className="sidebar-icon-v" /></div>
                <span class="link_name">{t('Analytics')}</span>
              </Link>
            </div>
          </li>
          <li>
            <div className={location?.pathname == "/reviews" || location.pathname === "/request-reviews" ? "icon-link active" : "icon-link"}>
              <Link to="/reviews" >
                <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/reviews-icon-v.svg`} alt="" className="sidebar-icon-v" /></div>
                <span class="link_name">{t('Reviews')}</span>
              </Link>
            </div>
          </li>
          <li>
            <div className={`d-flex flex-column align-items-start ${location.pathname === "/business-details" || location.pathname === "/contact-details" || location.pathname === "/location-and-maps"
              || location.pathname === "/faqs" || location.pathname === "/pricing-and-deals" || location.pathname === "/photos-and-videos"
              || location.pathname === "/social-network" || location.pathname === "/shadi_tayari-badge" || location.pathname === "/preferred-suppliers"
              || location.pathname === "/additional-details" ? "icon-link active" : "icon-link"}`}>
              <a onClick={toggleSubMenu}>
                <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/profile-icon-v.svg`} alt="" className="sidebar-icon-v" /></div>
                <span className="link_name d-flex align-items-center">{t('Profile')}
                  <i class="bi bi-chevron-down ms-3 pink-color"></i>
                </span>
              </a>
              {/* Submenu */}
              {showSubMenu && (
                <ul className="submenu color-black">
                  <li>
                    <div className={location?.pathname == "/business-details" ? "icon-link active" : "icon-link"}>
                      <Link to="/business-details" >
                        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/business-details-icon.svg`} alt="" className="sidebar-icon-v" /></div>
                        <span class="link_name">{t('Business')} {t('Details')}</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className={location?.pathname == "/additional-details" ? "icon-link active" : "icon-link"}>
                      <Link to="/additional-details">
                        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/business-details-icon.svg`} alt="" className="sidebar-icon-v" /></div>
                        <span class="link_name">{t('Additional')} {t('Details')}</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className={location?.pathname == "/contact-details" ? "icon-link active" : "icon-link"}>
                      <Link to="/contact-details">
                        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/contact-details-icon.svg`} alt="" className="sidebar-icon-v" /></div>
                        <span class="link_name">{t('Contact')} {t('Details')}</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className={location?.pathname == "/location-and-maps" ? "icon-link active" : "icon-link"}>
                      <Link to="/location-and-maps">
                        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/location-map-icon.svg`} alt="" className="sidebar-icon-v" /></div>
                        <span class="link_name">{t('LocationandMaps')}</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className={location?.pathname == "/faqs" ? "icon-link active" : "icon-link"}>
                      <Link to="/faqs">
                        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/faqs-icon.svg`} alt="" className="sidebar-icon-v" /></div>
                        <span class="link_name">{t('FAQs')}</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className={location?.pathname == "/pricing-and-deals" ? "icon-link active" : "icon-link"}>
                      <Link to="/pricing-and-deals">
                        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/pricing-deals-icon.svg`} alt="" className="sidebar-icon-v" /></div>
                        <span class="link_name">{t('Pricing&Deals')}</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className={location?.pathname == "/photos-and-videos" ? "icon-link active" : "icon-link"}>
                      <Link to="/photos-and-videos">
                        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/photos-videos-icon.svg`} alt="" className="sidebar-icon-v" /></div>
                        <span class="link_name">{t('Photos&Videos')}</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className={location?.pathname == "/social-network" ? "icon-link active" : "icon-link"}>
                      <Link to="/social-network">
                        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/social-network-icon.svg`} alt="" className="sidebar-icon-v" /></div>
                        <span class="link_name">{t('SocialNetwork')}</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className={location?.pathname == "/preferred-suppliers" ? "icon-link active" : "icon-link"}>
                      <Link to="/preferred-suppliers">
                        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/preferred-suppliers-icon.svg`} alt="" className="sidebar-icon-v" /></div>
                        <span class="link_name">{t('PreferredSuppliers')}</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className={location?.pathname == "/shadi_tayari-badge" ? "icon-link active" : "icon-link"}>
                      <Link to="/shadi_tayari-badge">
                        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/weddified-badge.svg`} alt="" className="sidebar-icon-v" /></div>
                        <span class="link_name">{t('Shadi Tayari Badge')}</span>
                      </Link>
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </li>
          <li>
            <div className={location?.pathname == "/bookings" || location.pathname === "/booking-details" ? "icon-link active" : "icon-link"}>
              <Link to="/bookings" >
                <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/bookings-icon-v.svg`} alt="" className="sidebar-icon-v" /></div>
                <span class="link_name">{t('Bookings')}</span>
              </Link>
            </div>
          </li>
          <li>
            <div className={location?.pathname == "/enquiries" ? "icon-link active" : "icon-link"}>
              <Link to="/enquiries" >
                <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/enquiries-icon-v.svg`} alt="" className="sidebar-icon-v" /></div>
                <span class="link_name">{t('Enquiries')}</span>
              </Link>
            </div>
          </li>
        </ul>
        </div>
      </div>
    </>
  )
}

export default Sidebar