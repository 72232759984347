import React from 'react'
import Sidebar from "../../components/Layout/SidebarVendor";
import AnalyticsChart from "./DashboardAnalyticsChart";
import SearchPositionGraph from "./DashboardSearchPositionGraph";
import StoreNavBar from './StoreNavBar';

const Analytics = () => {
  return (
    <>
    <section className="wf-vendor-dashboard">
        <div className="container-fluid">
        <div className="d-flex position-relative">  
            <Sidebar />
            <section class="home-section">
          {/* <StoreNavBar /> */}
                <AnalyticsChart />
                {/* <SearchPositionGraph /> */}
            </section>
        </div>
        </div>
    </section>
    </>
  )
}

export default Analytics