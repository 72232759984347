import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment"
import Sidebar from "../../components/Layout/SidebarVendor";
import {
    getEventList,
    getReviews
  } from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import StoreNavBar from "./StoreNavBar";


const ReviewsAndRanking = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const myEvents = useSelector(getEventList);

const [data,setdata]=useState("")


  const getAllReviews=async(store_id)=>{
    try {
      console.log('store_id', store_id)
        setdata(null);
        const response = await dispatch(getReviews({store_id: store_id})).unwrap();
        console.log('response21212222', response)
        setdata(response?.data);
      } catch (rejectedValueOrSerializedError) {}
  }
  
 
    
  useEffect(() => {
  
      
    if(myEvents){
          getAllReviews(myEvents?._id)
    }
          
         
       
      }, [myEvents]);
  
  return (
  <>
    <section className="wf-vendor-reviews">
        <div className="container-fluid">
        <div className="d-flex position-relative">  
    <Sidebar />
    <section class="home-section">
    {/* <StoreNavBar /> */}
    <div className="wf-shadow-2 white-bg p-4 ">
    <div className="row mb-4">
    <div className="col-md-6 d-flex gap-3 align-items-center">
        <img height="36" src={"../assets/images/reviews-icon-v.svg"} alt="" className="events-icon" />
        <h2 className="font-size-36 playfair-font color-black mb-0">{t('Reviews')} {data?.length>0&&data[0]?.overallAverage}({data?.length})</h2>
    </div>
    <div className="col-md-6 d-flex justify-content-end align-items-center">
    <button onClick={()=>navigate("/request-reviews")} class="btn wf-btn wf-pink-btn px-4 fw-bold py-2 text-uppercase">{t('RequestReviews')}</button>
    </div>
    </div>
   {data?.length>0&&data?.map((d) => 
         ( 
    <div className="bookings-list wf-shadow-2 white-bg rounded p-3 mb-3">
      <div className="row">
      <div className="col-md-2 d-flex flex-column align-items-center">
      <p className="font-size-26 pink-color text-center mb-0">{d?.reviews.average}</p>
        <div class="ratings boost-credibility-stars d-inline-block m-0">
        {[1, 2, 3, 4, 5].map((star) => (
          <i key={star} className={`bi ${star <= d?.reviews.average ? "bi-star-fill rating-color" : "bi-star"}`}></i>
        ))}
        </div>
      </div>
      {/* <div className="col-md-1"> */}
      <div className='wf-up-avatar d-flex mb-2'>
                                          <div className='avatar-couple-first'>
                                              <img width="57" height="57" alt=""  src={d?.couple?.person1?.imageName?`${process.env.REACT_APP_IMGURL}${d?.couple?.person1?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                                          </div>
                                          <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                                              <img width="57" height="57" alt="" src={d?.couple?.person2?.imageName?`${process.env.REACT_APP_IMGURL}${d?.couple?.person2?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                                          </div>
                                          <div>
            <p className="font-size-14 color-black mb-0">Couple</p>
            <p className="font-size-16 purple-color mb-0 fw-medium">{d?.couple?.person1?.name||"partner"}&{d?.couple?.person2?.name||"partner"}</p>
          </div>
                                      </div>
                                          {/* </div> */}
      
      <div className="col-md-9">
      <p className="font-size-14 pink-color mb-0">{moment(d?.createdAt).format("DD MMM YYYY")}</p>
      <p className="font-size-16 mb-0 fw-medium">{`${d?.reviews?.event_id?.event_name?.charAt(0).toUpperCase()+d?.reviews?.event_id?.event_name?.slice(1)}`}</p>
      <p className="font-size-14 color-black mb-0">{d?.reviews.comments}</p>
      <div className="row justify-content-evenly">
      <div className="col-md-4 d-flex gap-2 align-items-center">
      <p className="font-size-14 color-black mb-0 mt-1">{t('Quality')}: <span className="purple-color">{d?.reviews.quality}</span></p>
      <div class="ratings boost-credibility-stars d-inline-block">
      {[1, 2, 3, 4, 5].map((star) => (
      <i key={star} className={`bi ${star <= d?.reviews.quality ? "bi-star-fill rating-color" : "bi-star"}`}></i>
      ))}
      </div>
      </div>
      <div className="col-md-4 d-flex gap-2 align-items-center">
      <p className="font-size-14 color-black mb-0 mt-1">{t('Responsive')}: <span className="purple-color">{d?.reviews.responsive}</span></p>
      <div class="ratings boost-credibility-stars d-inline-block">
      {[1, 2, 3, 4, 5].map((star) => (
      <i key={star} className={`bi ${star <= d?.reviews.responsive ? "bi-star-fill rating-color" : "bi-star"}`}></i>
      ))}
      </div>
      </div>
      <div className="col-md-4 d-flex gap-2 align-items-center">
      <p className="font-size-14 color-black mb-0 mt-1">{t('Flexibility')}: <span className="purple-color">{d?.reviews.flexibility}</span></p>
      <div class="ratings boost-credibility-stars d-inline-block">
      {[1, 2, 3, 4, 5].map((star) => (
      <i key={star} className={`bi ${star <= d?.reviews.flexibility ? "bi-star-fill rating-color" : "bi-star"}`}></i>
      ))}
      </div>
      </div>
      <div className="col-md-5 d-flex gap-2 align-items-center">
      <p className="font-size-14 color-black mb-0 mt-1">{t('ValueforMoney')}: <span className="purple-color">{d?.reviews.value_for_money}</span></p>
      <div class="ratings boost-credibility-stars d-inline-block">
      {[1, 2, 3, 4, 5].map((star) => (
      <i key={star} className={`bi ${star <= d?.reviews.value_for_money ? "bi-star-fill rating-color" : "bi-star"}`}></i>
      ))}
      </div>
      </div>
      <div className="col-md-5 d-flex gap-2 align-items-center">
      <p className="font-size-14 color-black mb-0 mt-1">{t('Professionalism')}: <span className="purple-color">{d?.reviews.professionalism}</span></p>
      <div class="ratings boost-credibility-stars d-inline-block">
      {[1, 2, 3, 4, 5].map((star) => (
      <i key={star} className={`bi ${star <= d?.reviews.professionalism ? "bi-star-fill rating-color" : "bi-star"}`}></i>
      ))}
      </div>
      </div>
      </div>
      </div>
      
      </div>
      
    </div>
    )
   )} 
    </div>
  </section>
        </div>
        </div>
      </section>
  </>
  
  )
}

export default ReviewsAndRanking


