import React, { useEffect, useState, useRef  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment"
import Sidebar from "../../components/Layout/SidebarVendor";
import {
    getEventList,getProfile,getReviewLink,SendReviewMail
  } from "../../store/slices/userSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import StoreNavBar from "./StoreNavBar";


const RequestReviews = () => {
  const { t } = useTranslation(); 
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const myEvents = useSelector(getEventList);
  const profile = useSelector(getProfile);


  const [name,setname]=useState("")
  const [email,setemail]=useState("")
  const [link,setlink]=useState("")
  const [message,setmessage]=useState("")
  const [data,setdata]=useState([])


  const handleAdd=()=>{
    setdata(prevData => [
      ...prevData,
      { name: name, email: email }
    ]);
    setname("")
    setemail("")
  }

const copyText = () => {
    inputRef.current.select();
    navigator.clipboard.writeText(link)
      .then(() => {
        toast.success('Link copied to clipboard');
      })
      .catch((err) => {
        console.error('Unable to copy to clipboard', err);
      });
  };

  const getLink = async (store_id) => {
    try {
        setlink("");
        const response = await dispatch(getReviewLink({store_id: store_id, shadiTayari: false})).unwrap();
        console.log('response21212222', response)
        setlink(response?.data);
      } catch (rejectedValueOrSerializedError) {}
  };




  useEffect(() => {
  
      
    if(myEvents){
          getLink(myEvents?._id)
    }
          
         
       
      }, [myEvents]);


      const saveBranch = async (e) => {
        e.preventDefault();
    
        try {
  
  
          const requestData = {
            data: JSON.stringify(data),
            cc: profile?.email,
            message: message,
            link: link
          };
    
         
            
          const nav= await dispatch(
              SendReviewMail(requestData)
              ).unwrap();
              
              if(nav){
                setname("")
                setemail("")
                setmessage("")
                setdata([])
               }  
          
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      };

  return (
    <>
        <section className="wf-vendor-reviews">
        <div className="container-fluid">
        <div className="d-flex position-relative">  
    <Sidebar />
    <section class="home-section">
    {/* <StoreNavBar /> */}
    <div className="row">
    <div className="col-md-7">
    <div className="wf-shadow-2 white-bg p-4 rounded">
    <div className="d-flex gap-3 align-items-center mb-4">
        <img height="36" src={"../assets/images/reviews-icon-v.svg"} alt="" className="events-icon" />
        <h2 className="font-size-36 playfair-font color-black mb-0">{t('RequestReviews')}</h2>
    </div>
    <h3 className="font-size-26 playfair-font color-black mb-3">{t('Send')} {t('Email')}</h3>
    <div className="row gx-2 mb-3">
    <div className="col-md-5">
    <div class="form-floating wf-input wf-input-2">
        <input value={name} onChange={(e)=>setname(e.target.value)} type="text" class="form-control" id="recipients-name" placeholder="Recipients Name"/>
        <label for="recipients-name">{t('Recipients')} {t('Name')}</label>
    </div>
    </div>
    <div className="col-md-6 ">
    <div class="form-floating wf-input wf-input-2">
        <input value={email} onChange={(e)=>setemail(e.target.value)} type="text" class="form-control" id="recipients-email" placeholder="Recipients Email"/>
        <label for="recipients-email">{t('Recipients')} {t('Email')}</label>
    </div>
    </div>
    <div className="col-md-1 align-self-center">
    <button onClick={handleAdd} class="btn wf-btn wf-pink-btn pink-color fw-bold font-size-14 text-uppercase">{t('Add')}</button>
    </div>
    </div>
    <p className="font-size-14 color-black mb-1 fw-medium">{t('TO')}:</p>

    {data?.length>0?data?.map((d,i)=>(<div className="d-flex align-items-center justify-content-between mb-1">
    <p className="font-size-12 color-grey mb-0">{d.name}</p>
    <p className="font-size-12 color-grey mb-0">{d.email}</p>
    <i onClick={() => { const updatedRecipients = [...data]; updatedRecipients.splice(i, 1); setdata(updatedRecipients)}} class="font-size-14 bi bi-trash purple-color cursor-pointer"></i>
    </div>)):<p className="font-size-12 color-grey mb-0">{t('NoRecipientsAdded')}</p>}
  

    <p className="font-size-14 color-black mb-1 fw-medium mt-3">{t('CC')}:</p>
    <div className="d-flex align-items-center justify-content-between mb-3">
    <p className="font-size-12 color-grey mb-0">{profile.name}</p>
    <p className="font-size-12 color-grey mb-0">{profile.email}</p>
    </div>
    <div class="form-floating wf-textarea wf-textarea-3 mb-3">
    <textarea value={message} onChange={(e)=>setmessage(e.target.value)} class="form-control" placeholder="Message" id="message" style={{"height": "150px"}}></textarea>
    <label for="message" className="text-uppercase">{t('Message')}</label>
    </div>
    <div className="text-end">
      <button onClick={saveBranch} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">{t('Send')}</button>
    </div>
    </div>
    </div>
    <div className="col-md-5">
    <div className="wf-shadow-2 white-bg p-4 rounded">
      <h2 className="font-size-26 playfair-font color-black mb-2">{t('ShareReviewLink')}</h2>
      <p className="font-size-14 color-black mb-3">{t('Therearemanyvariations')}</p>
      <div className="input-group wf-input-group">
      <div className="form-floating wf-input">
        <input
          disabled
          type="text"
          className="form-control"
          id="review-link"
          value={link}
          ref={inputRef}
        />
        <label htmlFor="review-link">{t('Review')} {t('Link')}</label>
      </div>
      <button
        className="input-group-text wf-pink-btn pink-color fw-bold font-size-14"
        onClick={copyText}
      >
        {t('Copy')} {t('Link')}
      </button>
    </div>
    </div>
    </div>
    </div>
    
  </section>
        </div>
        </div>
      </section>
    </>
  )
}

export default RequestReviews