import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";
import Sidebar from "../../components/Layout/SidebarVendor";
import {
  getEventList,
  getProfile,
  updateUserProfile,
  deleteAccount,
  updatePassword
} from "../../store/slices/userSlice";

import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import StoreNavBar from "./StoreNavBar";
const customStyles = {
  content: {
    "display": "block",
    "--bs-modal-width": "35%",
  },
};
Modal.setAppElement("#root");

const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const dummy = "Hi! It's a pleasure to see you on our store, Please let us know how can we help you?"
  const navigate = useNavigate()


  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [userDetail, setUserDetail] = useState(null);




  const [select, setselected] = useState("vendor")
  const [image, setimage] = useState(profile?.imageName)
  const [name, setname] = useState(profile?.name)
  const [email, setemail] = useState(profile?.email)
  const [is_notification, setis_notification] = useState(profile?.is_notification);
  const [is_emailnotification, setis_emailnotification] = useState(profile?.is_emailnotification);
  const [message_templete, setmessage_templete] = useState(profile?.message_templete ? profile?.message_templete : dummy);
  const [abc, setabc] = useState(false)



  function viewModal() {
    setIsOpen(true);
    setModalType("delete");
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }

  const DeleteAccount = async () => {
    try {
      const abc = await dispatch(deleteAccount()).unwrap();
      setIsOpen(false);
      if (abc) {

        try {
          navigate("/login")
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      }

    }
    catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };



  const handleImageUpload = (e) => {

    setabc(true)


    const file = e.target.files[0];
    if (file) {

      setimage(file);

    }
  };



  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {

  //    const data = new FormData();

  //     data.append("imageName", image);
  //     data.append("name", name);
  //     data.append("email", email);
  //     data.append("is_notification", is_notification);
  //     data.append("is_emailnotification", is_emailnotification);
  //     data.append("message_templete", message_templete);

  //       await dispatch(updateUserProfile(data)).unwrap();


  //     }
  //    catch (rejectedValueOrSerializedError) {
  //     console.log(rejectedValueOrSerializedError);
  //   }
  // };

  console.log('name', name)
  console.log('exist', abc)

  const [old_password, setCurrentPassword] = useState("")
  const [new_password, setNewPassword] = useState("")
  const [confirm_password, setConfirmNewPassword] = useState("")
  const [isSecureEntry, setisSecureEntry] = useState(true)
  const [isSecureEntry2, setisSecureEntry2] = useState(true)
  const [isSecureEntry3, setisSecureEntry3] = useState(true)
  const handleSubmit1 = async (e) => {
    e.preventDefault();
    try {

      const data = new FormData();

      data.append("imageName", image);
      // data.append("name", name);
      // data.append("email", email);
      data.append("is_notification", is_notification);
      data.append("is_emailnotification", is_emailnotification);
      // data.append("message_templete", message_templete);

      await dispatch(updateUserProfile(data)).unwrap();

      if (old_password !== "" || new_password !== "" || confirm_password !== "") {
        await dispatch(updatePassword({ old_password, new_password, confirm_password })).unwrap()
      }
      setCurrentPassword('')
      setNewPassword('')
      setConfirmNewPassword('')


    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  }


  return (

    <>
      <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="delete"
        className="modal fade show wf-modal tasks"
      >
        <div class="modal-dialog wf-modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 align-items-start">
              <h1 class="font-size-26 dm-font purple-color" id="staticBackdropLabel">Delete Account Confirmation</h1>
              <button type="button" onClick={closeModal} class="btn-close mt-1" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body pb-4">
              <p className="font-size-16 color-black">Are you sure you want to delete your account?</p>
            </div>
            <div class="modal-footer border-0 justify-content-center gap-3">
              <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2 wf-shadow-2-2" onClick={closeModal}> Cancel</button>
              <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 wf-shadow-2-2" onClick={() => DeleteAccount()}> Delete Account</button>
            </div>

          </div>
        </div>

      </Modal>
      <section className="wf-vendor-settings">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* <StoreNavBar /> */}
              <div className="wf-shadow-2 white-bg p-4 rounded wf-profile-setting mb-4">
                <h1 className="font-size-36 playfair-font color-black mb-4">{t('Settings')}</h1>
                <div className="row mb-4 gy-3">
                  <div className="col-md-3">
                    <div class="avatar-upload vendor-profile">
                      <div class="avatar-edit">
                        <input onChange={(e) => handleImageUpload(e)} type='file' id="imageUpload2" accept=".png, .jpg, .jpeg" />
                        <label for="imageUpload2"></label>
                      </div>
                      <div class="avatar-preview">
                        {image && <img id="output" src={!abc ? `${process.env.REACT_APP_IMGURL}${image}` : URL.createObjectURL(image)} alt="" />}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9 align-self-center">
                    <form>
                      <div class="form-floating wf-input wf-input-101 mb-3">
                        <input class="form-control" id="opassword" placeholder="Password" value={old_password} onChange={(e) => setCurrentPassword(e.target.value)}
                          type={isSecureEntry ? "password" : "text"} autocomplete="password" required />
                        <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                        <label for="opassword">{t('Old')} {t('Password')}</label>
                      </div>
                      <div class="form-floating wf-input wf-input-101 mb-3">
                        <input class="form-control" placeholder="Password" value={new_password} id="password" onChange={(e) => setNewPassword(e.target.value)}
                          type={isSecureEntry2 ? "password" : "text"} autocomplete="password" required />
                        <i onClick={() => { setisSecureEntry2((prev) => !prev) }} className={`fa-solid ${isSecureEntry2 ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                        <label for="password">{t('Password')}</label>
                      </div>
                      <div class="form-floating wf-input wf-input-101">
                        <input class="form-control" placeholder="Password" value={confirm_password} id="cpassword" onChange={(e) => setConfirmNewPassword(e.target.value)}
                          type={isSecureEntry3 ? "password" : "text"} autocomplete="password" required />
                        <i onClick={() => { setisSecureEntry3((prev) => !prev) }} className={`fa-solid ${isSecureEntry3 ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                        <label for="cpassword">{t('Confirm')} {t('Password')}</label>
                      </div>
                    </form>
                  </div>
                </div>
                <h2 className="font-size-26 playfair-font color-black mb-1">{t('Notification')} {t('Settings')}</h2>
                <p className="font-size-14 color-black "></p>
                <div class="form-check wf-switch form-switch">
                  <input class="form-check-input" onChange={() => setis_notification(!is_notification)} checked={is_notification} type="checkbox" role="switch" id="flexSwitchCheckChecked1" />
                  <label class="form-check-label" for="flexSwitchCheckChecked1">{t('EnableorDisable')} {t('Notification')}</label>
                </div>
                <div class="form-check wf-switch form-switch">
                  <input class="form-check-input" onChange={() => setis_emailnotification(!is_emailnotification)} checked={is_emailnotification} type="checkbox" role="switch" id="flexSwitchCheckChecked2" />
                  <label class="form-check-label" for="flexSwitchCheckChecked2">{t('EnableorDisable')} {t('Email')} {t('Notification')}</label>
                </div>
                <div className="row mt-4 mb-4">
                  <div className="col-md-10">
                    <h2 className="font-size-26 playfair-font color-black mb-1">{t('Feedback')}</h2>
                    <p className="font-size-14 color-black ">{t('Welovehearing')}!</p>
                  </div>
                  <div className="col-md-2 ">
                    <button class="btn wf-purple-btn wf-btn btn-lg font-size-14">{t('Give')} {t('Feedback')}</button>
                  </div>
                </div>
                <div className="d-md-flex  justify-content-center gap-3">
                  <button class="btn wf-red-btn wf-btn btn-lg" onClick={() => viewModal()}>{t('Delete')} {t('Account')}</button>
                  <button class="btn wf-purple-btn wf-btn btn-lg" onClick={handleSubmit1}>{t('Save')} {t('Details')}</button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default Settings