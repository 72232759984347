import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { forgetPassword } from "../../store/slices/userSlice";
import { ArrowLeft } from 'lucide-react';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/Layout/LanguageSwitcher";
import i18n from "../../components/i18n";

const ForgetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const abc = await dispatch(forgetPassword({ email: email })).unwrap();
      navigate("/verify-account", {
        state: { user_id: abc.data, page: "forget_password" },
      });
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const currentLanguage = i18n.language;

  return (
    <>
      <section class="authentication-bg">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow-2-strong border-0 rounded">
            <div class="card-body py-5 px-md-5 px-3 text-center">
  
            <img onClick={()=>navigate("/")} className="mx-auto d-block auth-logo mb-5 wf-logo-card" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-arabic.png' : 'logo.svg'}`} alt="Shadi Tayari" width={currentLanguage === 'ar' ? '240px' : '270px'} />
            <h3 className="dm-font font-size-20 pink-color mb-5">{t('ForgotyourPassword')}?</h3>
              <p className="color-black font-size-14 mb-3">Please enter your email address you’d like your password Reset information sent to</p>

                <div class="form-floating wf-input mb-3">
              <input type="email" class="form-control" id="floatingInput" placeholder="name@weddifed.com" 
              onChange={(e) => setEmail(e.target.value)} autocomplete="email" maxLength={320} required />
              <label for="floatingInput">{t('YourEmailAddress')}</label>
              {/* <div class="invalid-feedback d-block">Incorrect Email Address</div> */}
            </div>
            <div class="d-grid gap-2 mt-2">
              <button class="btn wf-purple-btn wf-btn btn-lg" onClick={handleClick}>{t('Confirm')}</button>
            </div>

              </div>
            <div class="card-footer wf-auth-card-footer">
            <div className="text-center">
            <a>{t('Backto')} <b className="pink-color cursor-pointer" onClick={() => navigate("/login")}>{t('Login')}</b></a>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <p className="d-none"> <LanguageSwitcher /> </p>
    </>
  );
};

export default ForgetPassword;