import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"
import Sidebar from "../../components/Layout/SidebarVendor";
import {
  getChats, approvePayment, getAllBookings
} from "../../store/slices/userSlice";
import ChatMessage from "../VendorDashboard/ChatMessage";
import { CalendarDays, MapPin, Users, MessagesSquare, Banknote } from "lucide-react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import StoreNavBar from "./StoreNavBar";
const customStyles = {
  content: {
    display: "block",
  },
};
Modal.setAppElement("#root");

const BookingDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [toggle, settoggle] = useState(true);
  const [toggle1, settoggle1] = useState(true);
  const [toggle2, settoggle2] = useState(true);
  const [toggle3, settoggle3] = useState(true);

  const [paymentRecieved, setpaymentRecieved] = useState(true);
  const [value, setvalue] = useState(true);
  const location = useLocation();
  const [data, setdata] = useState(location?.state?.data)
  const [load, setload] = useState(false)
  const [currentChat, setcurrentChat] = useState();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();


  function viewModal({ type, value, paymentRecieved }) {

    console.log('firstassaas', type)
    setvalue(value)
    setpaymentRecieved(paymentRecieved)
    setIsOpen(true);

    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


  const getAllChats = async () => {
    try {
      const response = await dispatch(getChats()).unwrap();
      console.log('response', response)
      setcurrentChat(response?.data?.find(item => item.group_id === data?.group_id))
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const Details = async (booking_id) => {
    try {
      setload(true)
      const response = await dispatch(getAllBookings()).unwrap();
      const filter = response.data?.filter((f) => f._id == booking_id)
      console.log('response', response.data)
      console.log('filter', filter)
      setdata(filter[0])
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
    }
  };


  const approveInitDeposit = async ({ booking_id, offer_id }) => {
    try {
      const response = await dispatch(approvePayment({ booking_id: booking_id, offer_id: offer_id, paymentRecieved: paymentRecieved, value: value })).unwrap();
      if (response) {
        // setdata({...data,status:response.bookingStatus,offer_id: {...data.offer_id, price: response.data}
        Details(booking_id)
        // });
        closeModal()
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    let mount = true;
    if (mount) {
      getAllChats();
    }
    return () => {
      mount = false;
    };
  }, []);

  console.log('currentChat', currentChat)
  console.log('dataaaa2', data)
  console.log('modalType', modalType)

  return (
    <>
      <Modal
        // shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Guest"
        className="modal fade show wf-modal guests"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {modalType === "add" ? (
              <>
                <div class="modal-header border-0 pb-0">
                  <h1 class="font-size-36 playfair-font color-black" id="staticBackdropLabel">{t('Confirmation')}</h1>
                  <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="spacer-20"></div>
                <div className="modal-body">
                  <p className="color-black font-size-14 mb-2">{t('Areyousureyouwanttoapproveyourpayment')}?</p>
                  <div class="modal-footer border-0 justify-content-center gap-2">
                    <button class="btn wf-purple-btn wf-btn btn-lg" onClick={closeModal}><i class="far fa-cross"></i> {t('Cancel')}</button>
                    <button class="btn wf-purple-btn wf-btn btn-lg" onClick={() => approveInitDeposit({ booking_id: data?._id, offer_id: data?.offer_id?._id })}><i class="fa-regular fa-floppy-disk"></i> Approve</button>
                  </div>
                </div>
              </>
            ) : modalType === "delete" ? (
              <>
                <div class="modal-header border-0 pb-0">
                  <h1 class="font-size-36 playfair-font color-black" id="staticBackdropLabel">{t('Delete')} {t('Category')}</h1>
                  <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="spacer-20"></div>
                <div className="modal-body">
                  <p className="color-black font-size-14 mb-2">{t('Areyousureyouwanttodeletethis')} {t('Category')}</p>
                  <div class="modal-footer border-0 justify-content-center gap-2">
                    <button class="btn purple-color wf-btn btn-lg" onClick={closeModal}><i class="far fa-cross"></i> {t('Cancel')}</button>
                    <button class="btn purple-color wf-btn btn-lg" ><i class="far fa-trash-alt"></i> {t('Delete')} {t('Category')}</button>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>

      {/* <section className="wf-vendor-bookings">
        <div className="container">
        <div className="d-flex position-relative">  
    <Sidebar />
  <section class="home-section">
  <div className="wf-shadow-2 white-bg p-4 rounded mb-3">
    <div className="d-flex gap-3 align-items-center">
        <img height="36" src={"../assets/images/bookings-icon-v.svg"} alt="" className="events-icon" />
        <h2 className="font-size-36 playfair-font color-black mb-0">{t('Booking')} {t('Details')}</h2>
    </div>
  </div>
  <div className="row">
  <div className="col-md-6 faq-list">
  <div className="wf-shadow-2 white-bg rounded p-4 mb-3">
    <div onClick={()=>settoggle(!toggle)} className="d-flex align-items-center justify-content-between cursor-pointer">
    <p className="font-size-14 purple-color mb-0 text-uppercase fw-medium">{t('Couple')} {t('Details')}</p>
    <div className="arrow-toggle pink-color">
    {toggle?<i class="fas fa-chevron-up"></i>:<i class="fas fa-chevron-down"></i>}
    </div>
    </div>
    <div className={`answer ${toggle&&"show"}`}>
    <div className="d-flex justify-content-between align-items-center mb-3">
    <p className="font-size-16 pink-color mb-0 text-uppercase fw-semibold">{data?.user_event?.user_id?.person1?.name}</p>
    <div class="d-flex wf-booking-couple justify-content-center">
		<img class="rounded-circle profile-1" alt="avatar" src={data?.user_event?.user_id?.person1?.imageName?`${process.env.REACT_APP_IMGURL}${data?.user_event?.user_id?.person1?.imageName}`:"https://via.placeholder.com/50x50"} />
        <img alt="avatar" src={data?.user_event?.user_id?.person2?.imageName?`${process.env.REACT_APP_IMGURL}${data?.user_event?.user_id?.person2?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle profile-2" />
    </div>
    <p className="font-size-16 pink-color mb-0 text-uppercase fw-semibold">{data?.user_event?.user_id?.person2?.name||data?.user_event?.user_id?.person1?.user_purpose=="groom"?"bride":"groom"}</p>
    </div>
    <div className="d-flex justify-content-between align-items-center mb-1">
    <p className="font-size-14 purple-color mb-0 fw-medium">{data?.user_event?.user_id?.person1?.email?data?.user_event?.user_id?.person1?.email:""}</p>
    <i class="bi bi-envelope purple-color font-size-24"></i>
    <p className="font-size-14 purple-color mb-0 fw-medium">{data?.user_event?.user_id?.person2?.email?data?.user_event?.user_id?.person2?.email:""}</p>
    </div>
    <div className="d-flex justify-content-between align-items-center">
    <p className=" d-flex justify-content-between align-items-center font-size-14 pink-color mb-0 fw-medium">{data?.user_event?.user_id?.person1?.phone?data?.user_event?.user_id?.person1?.phone:""}</p>
    {data?.user_event?.user_id?.person1?.phone||data?.user_event?.user_id?.person2?.phone&&(<i class="bi bi-telephone pink-color font-size-24"></i>)}
    <p className="font-size-14 pink-color mb-0 fw-medium">{data?.user_event?.user_id?.person2?.phone?data?.user_event?.user_id?.person2?.phone:""}</p>
    </div>
    </div>
  </div>

  <div className="wf-shadow-2 white-bg rounded p-4 mb-3">
    <div onClick={()=>settoggle1(!toggle1)} className="d-flex align-items-center justify-content-between cursor-pointer">
    <p className="font-size-14 purple-color mb-0 text-uppercase fw-medium">{t('Event')} {t('Details')}</p>
    <div className="arrow-toggle pink-color">
    {toggle1?<i class="fas fa-chevron-up"></i>:<i class="fas fa-chevron-down"></i>}
    </div>
    </div>
    <div className={`answer ${toggle1&&"show"}`}>
    <div className="d-flex justify-content-between align-items-center mb-1">
    <p className="font-size-14 pink-color mb-0 fw-medium"><i class="bi bi-calendar-check font-size-18"></i>&nbsp;&nbsp; <span className="purple-color">{moment(data?.user_event?.event_date).format("DD MMM YYYY")}</span></p>
    </div>
    <div className="d-flex justify-content-between align-items-center mb-1">
    <p className="font-size-14 pink-color mb-0 fw-medium"><i class="bi bi-shop-window font-size-18"></i>&nbsp;&nbsp; <span className="purple-color">{data?.venue}</span></p>
    <p className="font-size-14 pink-color mb-0 fw-medium"><i class="bi bi-geo-alt font-size-18"></i>&nbsp;&nbsp; <span className="purple-color">{data?.user_event?.event_city?.name}, {data?.user_event?.event_city?.country_id?.name}</span></p>
    </div>
    <div className="d-flex justify-content-between align-items-center mb-1">
    <p className="font-size-14 pink-color mb-0 fw-medium">&nbsp;&nbsp;</p>
    <p className="font-size-14 pink-color mb-0 fw-medium"><i class="bi bi-people font-size-18"></i>&nbsp;&nbsp; <span className="purple-color">{data?.user_event?.no_of_guests} {t('Guests')}</span></p>
    </div>
    </div>
  </div>

  {data?.offer_id?.comments&&(<div className="wf-shadow-2 white-bg rounded p-4 mb-3">
    <div onClick={()=>settoggle2(!toggle2)} className="d-flex align-items-center justify-content-between cursor-pointer">
    <p className="font-size-14 purple-color mb-0 text-uppercase fw-medium">{t('Booking')} {t('Comittment')}</p>
    <div className="arrow-toggle pink-color">
    {toggle2?<i class="fas fa-chevron-up"></i>:<i class="fas fa-chevron-down"></i>}
    </div>
    </div>
    <div className={`answer ${toggle2&&"show"}`}>
    <p className="font-size-14 pink-color mb-1 text-uppercase fw-medium">{t('Booking')} {t('Confirmed')}</p>
    <div className="white-bg wf-shadow-2 p-2 rounded">
    <p className="font-size-14 color-black mb-0">{data?.offer_id?.comments}</p>
    </div>
    </div>
  </div>)}

  <div className="wf-shadow-2 white-bg rounded p-4 mb-3">
    <div onClick={()=>settoggle3(!toggle3)} className="d-flex align-items-center justify-content-between cursor-pointer">
    <p className="font-size-14 purple-color mb-0 text-uppercase fw-medium">{t('Paymentterms')}</p>
    <div className="arrow-toggle pink-color">
    {toggle3?<i class="fas fa-chevron-up"></i>:<i class="fas fa-chevron-down"></i>}
    </div>
    </div>
    <div className={`answer ${toggle3&&"show"}`}>
    <div className="d-flex align-items-center justify-content-between mb-2">
    <p className="font-size-14 purple-color mb-0 text-uppercase fw-medium">{t('Totalprice')}:</p>
    <div className="white-bg wf-shadow-2 py-2 px-3 rounded">
    <p className="font-size-14 pink-color mb-0 text-uppercase ">SAR {data?.offer_id?.price?.totalPrice}</p>
    </div>
    </div>
    <div className="d-flex align-items-center justify-content-between mb-2">
    <p className="font-size-14 purple-color mb-0 text-uppercase fw-medium">{t('Initialdeposit')}:</p>

<div className="col-md-6 text-end align-self-end">
{!data?.initialDepositPaid
 ? (<button onClick={()=>viewModal({type: "add", value: "initialDeposit", paymentRecieved: data?.initialDeposit})} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">Approve</button>):(<div className="fw-bold text-uppercase color-white wf-shadow-2 py-2 px-3 rounded wf-pink-btn"><p className={`font-size-14 white-color mb-0 text-uppercase`}>Paid</p></div>)}
</div>
    <div>
    <div className="white-bg wf-shadow-2 py-2 px-3 rounded">


    <p className="font-size-14 pink-color mb-0 text-uppercase ">SAR {data?.offer_id?.price?.initialDeposit}</p>
    </div>
    <p className="font-size-10 purple-color mb-0 text-uppercase text-center">By {moment(data?.offer_id?.price?.initialDepositDate).format("DD MMM YYYY")}</p>
    </div>
    </div>
    <div className="d-flex align-items-center justify-content-between mb-2">
    <p className="font-size-14 purple-color mb-0 text-uppercase fw-medium">{t('FinalPayment')}:</p>
    
<div className="col-md-6 text-end align-self-end">
{data?.initialDepositPaid && !data?.finalDepositPaid?(<button onClick={()=>viewModal({type: "add", value: "finalDeposit", paymentRecieved: data?.finalDeposit})} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">Approve</button>):data?.initialDepositPaid && data?.finalDepositPaid?(<div className="fw-bold text-uppercase color-white wf-shadow-2 py-2 px-3 rounded wf-pink-btn"><p className={`font-size-14 white-color mb-0 text-uppercase`}>Paid</p></div>):null}
</div>
    <div>
    <div className="white-bg wf-shadow-2 py-2 px-3 rounded">
    <p className="font-size-14 pink-color mb-0 text-uppercase ">SAR {data?.offer_id?.price?.totalPrice-data?.offer_id?.price?.initialDeposit}</p>
    </div>
    <p className="font-size-10 purple-color mb-0 text-uppercase text-center">{t('By')} {moment(data?.offer_id?.price?.finalDepositDate).format("DD MMM YYYY")}</p>
    </div>
    </div>
    <div className="d-flex align-items-center justify-content-between mb-2">
    <p className="font-size-14 purple-color mb-0 text-uppercase fw-medium">{t('PaymentMode')}:</p>
    <div className="d-flex align-items-center gap-2">
    <div className={`color-white wf-shadow-2 py-2 px-3 rounded ${data?.offer_id?.price?.paymentMode=="cash"?"wf-pink-btn":""}`}>
    <p className={`font-size-14 ${data?.offer_id?.price?.paymentMode=="cash"?"white-color":""} mb-0 text-uppercase`}>{t('Cash')}</p>
    </div>
    <div className={`white-bg wf-shadow-2 py-2 px-3 rounded ${data?.offer_id?.price?.paymentMode=="bank"?"wf-pink-btn":""}`}>
    <p className={`font-size-14 ${data?.offer_id?.price?.paymentMode=="bank"?"white-color":""} mb-0 text-uppercase`}>{t('BankTransfer')}</p>
    </div>
    </div>
    </div>
    <p className="font-size-10 color-grey mb-0">*{t('Agreedon')} {moment(data?.createdAt).format("DD MMM YYYY")}</p>
    </div>
  </div>
  </div>
  <div className="col-md-6">

  <ChatMessage currentChat={currentChat} getAllChats={getAllChats}/>

  </div>
  </div>
    
  </section>
        </div>
        </div>
      </section> */}

      <section className="wf-vendor-bookings">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section className="home-section">
              {/* <StoreNavBar /> */}
              <div className="d-flex gap-3 align-items-center mb-4">
                <h2 className="font-size-36 purple-color mb-0 dm-font">{t('Booking')} {t('Details')}</h2>
              </div>
              <div className="row">
                <div className="col-12 faq-list">
                  <div className="wf-shadow-2 white-bg rounded-3 py-4 mb-3">

                    {/* Row 1 */}
                    <div className='row px-4 mb-4'>
                      <div className='col-12'>
                        <h3 className="dm-font font-size-20 mb-3">{t('Couple')} {t('Details')}</h3>
                        <div className="d-flex justify-content-between gap-2 gap-md-5">
                          <div className='d-flex flex-md-row flex-column flex-wrap gap-3 gap-md-5'>
                            <div className='d-flex flex-lg-row flex-column gap-2 gap-md-4'>
                              <div className='wf-up-avatar'>
                                <div className='avatar-couple-first'>
                                  <img width="75" height="75" alt="" src={data?.user_event?.user_id?.person1?.imageName
                                    ? `${process.env.REACT_APP_IMGURL}${data?.user_event?.user_id?.person1?.imageName}` :
                                    "https://via.placeholder.com/50x50"} className="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                                </div>
                              </div>
                              <div>
                                <h3 className="font-size-30 dm-font fw-normal purple-color mb-0">{data?.user_event?.user_id?.person1?.name ? data?.user_event?.user_id?.person1?.name : ""}</h3>
                                <p className='font-size-12 fw-normal color-grey mb-0'><span>{data?.user_event?.user_id?.person1?.user_purpose ? data?.user_event?.user_id?.person1?.user_purpose : ""}</span></p>
                                <p className='font-size-12 purple-color mb-0'>{data?.user_event?.user_id?.person1?.phone ? data?.user_event?.user_id?.person1?.phone : ""}
                                  <br />{data?.user_event?.user_id?.person1?.email ? data?.user_event?.user_id?.person1?.email : ""}
                                </p>
                                <div className="font-size-12 purple-color mb-0">
                                  {/* <span className="cursor-pointer"><u>Send Invite</u></span> */}
                                </div>
                              </div>
                            </div>
                            <div className='d-flex flex-lg-row flex-column gap-2 gap-md-4'>
                              <div className='wf-up-avatar'>
                                <div className='avatar-couple-first'>
                                  <img width="75" height="75" alt="" src={data?.user_event?.user_id?.person2?.imageName
                                    ? `${process.env.REACT_APP_IMGURL}${data?.user_event?.user_id?.person2?.imageName}`
                                    : "https://via.placeholder.com/50x50"} className="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                                </div>
                              </div>
                              <div>
                                <h3 className="font-size-30 dm-font fw-normal pink-color mb-0">{data?.user_event?.user_id?.person2?.name || data?.user_event?.user_id?.person1?.user_purpose == "groom" ? "bride" : "groom"}</h3>
                                <p className='font-size-12 fw-normal color-grey mb-0'><span>{data?.user_event?.user_id?.person2?.user_purpose ? data?.user_event?.user_id?.person2?.user_purpose : ""}</span></p>
                                <p className='font-size-12 pink-color mb-0'>{data?.user_event?.user_id?.person2?.phone ? data?.user_event?.user_id?.person2?.phone : ""}
                                  <br />{data?.user_event?.user_id?.person2?.email ? data?.user_event?.user_id?.person2?.email : ""}
                                </p>
                                <div className="font-size-12 pink-color mb-0">
                                  {/* <span className="cursor-pointer"><u>Send Invite</u></span> */}
                                </div>
                              </div>
                            </div>

                          </div>
                          <a className="font-size-16 pink-color text-end mb-0" onClick={() => navigate("/enquiries", { state: { data: data?.offer_id?.group_id, chattype: "conversation" } })}><MessagesSquare stroke="var(--color4)" size={32} /></a>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom w-100 mb-4"></div>
                   
                    <div className='row px-4 mb-4'>
                      <div className='col-12'>
                        <h3 className="dm-font font-size-20 mb-3">{t('Booking')} {t('Agreement')}</h3>
                        <p className="font-size-16 mb-0">{data?.offer_id?.comments}</p>
                      </div>
                    </div>
                    <div className="border-bottom w-100 mb-4"></div>
                    {/* Row 3 */}
                    <div className='row px-4 mb-4'>
                      <div className='col-12'>
                        <h3 className="dm-font font-size-20 mb-3">{t('Event')} {t('Details')}</h3>
                        <div className="d-flex flex-wrap gap-5">
                          <div className="d-flex align-items-center gap-2">
                            <div><Users stroke="var(--color4)" size={32} /></div>
                            <div>
                              <p className="font-size-16 color-grey mb-0">{t('Number')} {t('of')} {t('Guests')}</p>
                              <p className="font-size-14 mb-0">{data?.user_event?.no_of_guests ? data?.user_event?.no_of_guests : "0"}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <div><CalendarDays stroke="var(--color4)" size={32} /></div>
                            <div>
                              <p className="font-size-16 color-grey mb-0">{t('Booking')} {t('Date')}</p>
                              <p className="font-size-14 mb-0">{moment(data?.createdAt).format("DD MMM YYYY")}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <div><CalendarDays stroke="var(--color4)" size={32} /></div>
                            <div>
                              <p className="font-size-16 color-grey mb-0">{t('Event')} {t('Date')}  </p>
                              <p className="font-size-14 mb-0">{moment(data?.user_event?.event_date).format("DD MMM YYYY")}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <div><MapPin stroke="var(--color4)" size={32} /></div>
                            <div>
                              <p className="font-size-16 color-grey mb-0">{t('Location')}</p>
                              <p className="font-size-14 mb-0">{data?.user_event?.event_city ? data?.user_event?.event_city?.name : ""}, {data?.user_event?.event_city ? data?.user_event?.event_city?.country_id?.name : ""}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom w-100 mb-4"></div>
                    {/* Row 4 */}
                    <div className='row px-4 mb-4'>
                      <div className='col-12'>
                        <h3 className="dm-font font-size-20 mb-3">{t('Booking')} {t('Terms')}</h3>
                        <div className="row">
                          <div className="col-md-6 col-xl-4">
                            <div className="d-flex gap-2">
                              <div>{<Banknote stroke="var(--color4)" size={32} />}</div>
                              <div>
                                <p className="color-grey font-size-14 mb-0">{t('Total')} {t('Price')} </p>
                                <p className="color-black font-size-16">AED {data?.offer_id?.price?.totalPrice}</p>
                                <p className="color-grey font-size-14 mb-0">{t('Booked')} {t('on')}</p>
                                <p className="color-black font-size-16">{moment(data?.createdAt).format("DD MMM YYYY")}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-4">
                            <div className="d-flex gap-2">
                              <div><Banknote stroke="var(--color4)" size={32} /></div>
                              <div>
                                <p className="color-grey font-size-14 mb-0">{t('Initial')} {t('Payment')}</p>
                                <p className="color-black font-size-16">AED {data?.offer_id?.price?.initialDeposit}</p>
                                {/* <div className="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 mb-2">
                                                                    <p className="font-size-14 white-color mb-0 text-uppercase">Mark as paid</p>
                                                                </div> */}
                                {!data?.initialDepositPaid
                                  ? (<button onClick={() => viewModal({ type: "add", value: "initialDeposit", paymentRecieved: data?.initialDeposit })} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">Approve</button>)
                                  : (<div className="fw-bold text-uppercase color-white wf-shadow-2 py-2 px-3 rounded wf-pink-btn"><p className={`font-size-14 white-color mb-0 text-uppercase`}>Paid</p></div>)}
                                {/* <p className="color-grey font-size-14 mb-0">{t('Paid on')}</p>
                                <p className="color-black font-size-16">{moment(data?.offer_id?.price?.initialDepositDate).format("DD MMM YYYY")}</p> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-4">
                            <div className="d-flex gap-2">
                              <div><Banknote stroke="var(--color4)" size={32} /></div>
                              <div>
                                <p className="color-grey font-size-14 mb-0">{t('Final')} {t('Payment')}</p>
                                <p className="color-black font-size-16">AED {data?.offer_id?.price?.totalPrice - data?.offer_id?.price?.initialDeposit}</p>
                                {/* <div className="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 mb-2">
                                                                    <p className="font-size-14 white-color mb-0 text-uppercase">Mark as paid</p>
                                                                </div> */}
                                {data?.initialDepositPaid && !data?.finalDepositPaid
                                  ? (<button onClick={() => viewModal({ type: "add", value: "finalDeposit", paymentRecieved: data?.finalDeposit })} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">Approve</button>)
                                  : data?.initialDepositPaid && data?.finalDepositPaid ? (<div className="fw-bold text-uppercase color-white wf-shadow-2 py-2 px-3 rounded wf-pink-btn"><p className={`font-size-14 white-color mb-0 text-uppercase`}>Paid</p></div>) : null}
                                {/* <p className="color-grey font-size-14 mb-0">{t('Paid on')}</p>
                                <p className="color-black font-size-16">{moment(data?.offer_id?.price?.finalDepositDate).format("DD MMM YYYY")}</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom w-100 mb-4"></div>
                    {/* Row 5 */}
                    <div className='row px-4 mb-4'>
                      <div className='col-12'>
                        <h3 className="dm-font font-size-20 mb-3">{t('Payment')} {t('Mode')} </h3>
                        <div className="d-flex align-items-center gap-2">
                          <div className={`color-white wf-shadow-2 py-2 px-3 rounded ${data?.offer_id?.price?.paymentMode == "cash" ? "wf-pink-btn" : ""}`}>
                            <p className={`font-size-14 ${data?.offer_id?.price?.paymentMode == "cash" ? "white-color" : ""} mb-0 text-uppercase`}>{t('Cash')}</p>
                          </div>
                          <div className={`${data?.offer_id?.price?.paymentMode == "bank" ? "wf-pink-btn wf-shadow-2 py-2 px-3 rounded" : ""}`}>
                            <p className={`font-size-12 ${data?.offer_id?.price?.paymentMode == "bank" ? "white-color" : "purple-color"} mb-0 text-uppercase`}><u>{t('Bank')} {t('Transfer')}</u></p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

    </>
  )
}

export default BookingDetails