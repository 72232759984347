import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Layout/SidebarVendor";
import AnalyticsChart from "./DashboardAnalyticsChart";
import SearchPositionGraph from "./DashboardSearchPositionGraph";
import {
  getEventList, getDashboard, dashboard, Firstlogin
} from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import StoreNavBar from "./StoreNavBar";


const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myEvents = useSelector(getEventList);
  const dashboardData = useSelector(getDashboard)

  const [initdata, setinitdata] = useState({})
  const [count, setcount] = useState(0)
  const [completionPercentage, setcompletionPercentage] = useState(0)


  let requiredFields = [
    "store_name",
    "description",
    "overview",
    "weddings_hosted",
    "team_members",
    "min_price",
    "max_price",
    "prefered_services",
    "prefered_typeofservices",
    "sub_services",
    "attributes",
    "sub_attributes",
    "location",
    "insurance",
    "business_attributes",
    "business_ethnics",
    "contact_person",
    "email",
    "mobile",
    "website",
    "country",
    "cities",
    "areas",
    "business_startdate",
    "video",
    "faqs",
    "store_pricing",
    "store_deal",
    "image"
  ];
  let totalQuestions = requiredFields.length;




  const Percentage = (arr) => {
    console.log('arr', arr)
    if (arr.length > 0 && arr[0]?.pricing_type == "pricing") {
      console.log('firstrr')
      requiredFields = requiredFields.filter(item => item !== "store_deal");
      totalQuestions = requiredFields.length;
    }

    if (arr.length > 0 && arr[0]?.pricing_type == "deal") {
      requiredFields = requiredFields?.filter(item => item !== "store_pricing");
      totalQuestions = requiredFields?.length;
    }

    console.log('requiredFields', requiredFields)

    const filledFields = requiredFields.reduce((acc, field) => {

      const fieldValue = myEvents[field];

      console.log('fieldValue', field, fieldValue, typeof (fieldValue))


      if (typeof fieldValue === 'string') {

        if (fieldValue.trim() !== '') {
          return acc + 1;
        }

      } else if (Array.isArray(fieldValue)) {

        if (fieldValue.length > 0) {
          return acc + 1;
        }
      }
      else if (typeof (fieldValue) === 'object') {

        if (fieldValue !== null) {
          return acc + 1;
        }
      }

      else if (typeof fieldValue === 'number') {

        if (fieldValue !== null) {
          return acc + 1;
        }
      }
      else {
        console.log('fieldValueemptyyy', fieldValue)
      }

      return acc;
    }, 0);


    setcount(filledFields);

    console.log('filledFields', filledFields,totalQuestions)


    setcompletionPercentage(((filledFields / totalQuestions) * 100).toFixed(0));
  }




  const Profile = async () => {
    try {
      await dispatch(Firstlogin()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  async function data(id) {
    try {
      console.log('dataaaaaaa34', id)
      const response = await dispatch(dashboard({ store_id: id })).unwrap()
      console.log('response.data23', response.data)
      setinitdata(response.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError)
    }
  }


  useEffect(() => {
    let mount = true
    if (mount && !dashboardData) {
      data(myEvents?._id);
    }
    return () => {
      mount = false
    }
  }, [dispatch, myEvents])





  useEffect(() => {

    Profile();

  }, [])

  useEffect(() => {
    if (myEvents) {
      Percentage(myEvents?.prefered_services);
    }
  }, [myEvents, completionPercentage])



  console.log('initdata', initdata)

  return (
    <>
      <section className="wf-vendor-dashboard">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* Heading */}
              <div className='row mb-4'>
                <div className='col-12'>
                  <h2 class="font-size-30 dm-font purple-color mb-0">Dashboard</h2>
                </div>
              </div>

              {/* <StoreNavBar /> */}
              <div className="row gy-3 mb-4">
                <div className="col-xl-9">
                  <div className="row gy-3">
                    <div className="col-xl-3 col-6">
                      <div className="wf-shadow-2 white-bg rounded p-3 text-center">
                        <img src="../assets/images/views-icon.svg" alt="" height="25px" className="mx-auto d-block mb-2" />
                        <h3 className="font-size-28 pink-color mb-0 dm-font">{initdata?.profileCount30days}</h3>
                        <p className="font-size-20 text-uppercase purple-color dm-font mb-1">{t('Views')}</p>
                        <p className="font-size-16 color-grey mb-0">{t('inthelast30days')}</p>
                      </div>
                    </div>
                    <div className="col-xl-3 col-6">
                      <div className="wf-shadow-2 white-bg rounded p-3 text-center">
                        <img src="../assets/images/leads-icon.svg" alt="" height="25px" className="mx-auto d-block mb-2" />
                        <h3 className="font-size-28 pink-color mb-0 dm-font">{initdata?.leads}</h3>
                        <p className="font-size-20 text-uppercase purple-color dm-font mb-1">{t('Leads')}</p>
                        <p className="font-size-16 color-grey mb-0">{t('inthelast30days')}</p>
                      </div>
                    </div>
                    <div className="col-xl-3 col-6">
                      <div className="wf-shadow-2 white-bg rounded p-3 text-center">
                        <img src="../assets/images/bookings-icon.svg" alt="" height="25px" className="mx-auto d-block mb-2" />
                        <h3 className="font-size-28 pink-color mb-0 dm-font">{initdata?.bookingsCount30days}</h3>
                        <p className="font-size-20 text-uppercase purple-color dm-font mb-1">{t('Bookings')}</p>
                        <p className="font-size-16 color-grey mb-0">{t('inthelast30days')}</p>
                      </div>
                    </div>
                    <div className="col-xl-3 col-6">
                      <div className="wf-shadow-2 white-bg rounded p-3 text-center">
                        <img src="../assets/images/reviews-icon.svg" alt="" height="25px" className="mx-auto d-block mb-2" />
                        <h3 className="font-size-28 pink-color mb-0 dm-font">{initdata?.reviews && initdata?.reviewstotal ? `${initdata?.reviews} (${initdata?.reviewstotal})` : 0}</h3>
                        <p className="font-size-20 text-uppercase purple-color dm-font mb-1">{t('Reviews')}</p>
                        <p className="font-size-16 color-grey mb-0">{t('inthelast30days')}</p>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-xl-3">
                  <div className="wf-shadow-2 white-bg p-3 rounded wf-profile-score">
                    <div className="row mb-2">
                      <div className="col-12">
                        <div className="text-end">
                          <Link className="font-size-18 pink-color mb-0" to={"/business-details"}>{t('Updateprofile')}</Link>
                        </div>
                        <h2 className="font-size-30 playfair-font purple-color mb-2">{t('ProfileScore')}</h2>
                        <p className="font-size-20 pink-color mb-1">{completionPercentage}%</p>
                        <div class="progress" style={{ "height": "14px", "width": "100%" }}>
                          <div class="progress-bar" role="progressbar" aria-label="Example 20px high" style={{ "width": `${completionPercentage}%`, "backgroundColor": "#804199" }} aria-valuenow={completionPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="wf-shadow-2 white-bg p-3 rounded wf-boost-credibility mb-3">
                    <div className="row mb-2">
                      <div className="col-md-12 d-flex justify-content-between align-items-center">
                        <h2 className="font-size-22 playfair-font color-black mb-0">{t('BoostCredibility')}</h2>
                        <Link to={"/request-reviews"} className="font-size-14 pink-color mb-0 cursor-pointer"><u>{t('RequestReviews')}</u></Link>
                      </div>
                    </div>
                    <p className="font-size-14 color-black mb-0">{t('Itisalongestablished')}</p>
                    <div class="d-flex align-items-center">
                      <div class="ratings boost-credibility-stars">
                        <div class="d-flex align-items-center">
                          <div class="ratings boost-credibility-stars d-inline-block m-0">
                            {[1, 2, 3, 4, 5].map((star) => (
                              <i key={star} className={`bi ${star <= initdata?.reviews ? "bi bi-star-fill rating-color" : "bi bi-star"}`}></i>
                            ))}
                          </div>

                          <p style={{ marginLeft: "5px" }} className="font-size-16 pink-color mb-0">{initdata?.reviews} <span className="purple-color">{initdata?.reviewstotal ? `(${initdata?.reviewstotal})` : `(${0})`}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wf-shadow-2 white-bg p-3 rounded wf-download-app mb-3">
                    <div className="row mb-2">
                      <div className="col-md-12 d-flex justify-content-between align-items-center">
                        <h2 className="font-size-22 playfair-font color-black mb-0">{t('DownloadtheApp')}</h2>
                      </div>
                    </div>
                    <p className="font-size-14 color-black mb-2">{t('Easilyplanyourweddinganytime')}</p>
                    <div className="d-flex gap-2">
                      <img class="apple-store-img w-50 wf-shadow-2" src="../assets/images/apple-store-download.svg" alt="" />
                      <img class="google-store-img w-50 wf-shadow-2" src="../assets/images/google-play-download.svg" alt="" />
                    </div>
                  </div>
                  <div className="wf-shadow-2 white-bg p-3 rounded wf-contact-support mb-3">
                    <div className="row mb-2">
                      <div className="col-md-12 d-flex justify-content-between align-items-center">
                        <h2 className="font-size-22 playfair-font color-black mb-0">{t('ContactShadi TayariSupport')}</h2>
                      </div>
                    </div>
                    <p className="font-size-14 color-black mb-2">Shadi Tayari support text goes here. Support text goes here.</p>
                    <Link className="font-size-14 pink-color mb-0 cursor-pointer"><u>{t('Contactsupport')}</u></Link>
                  </div> */}
                </div>
              </div>

              {/* next row analytics */}
              <div className="row mb-4">
                <div className="col-12">
                  <AnalyticsChart />
                  {/* <SearchPositionGraph /> */}
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

    </>
  )
}

export default Dashboard