import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { verifyAccount, resendOTP } from "../../store/slices/userSlice";
import $ from "jquery";
import { ArrowLeft } from 'lucide-react';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/Layout/LanguageSwitcher";
import i18n from "../../components/i18n";


const SignUp1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOTP] = useState("");
 



  const handleKeyPress = (e) => {
    const isValidKey = /^\d$/.test(e.key) || e.keyCode === 8;

    if (!isValidKey) {
      e.preventDefault();
    }
  };

 

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        verifyAccount({
          otp: parseInt(otp),
          id: location.state.user_id,
          page: location.state.page,
        })
      ).unwrap();

      if (location.state.page === "forget_password") {
        navigate("/newpassword", {
          state: { user_id: location.state.user_id },
        });
      } else {
        navigate("/confirmation", { state: { user_id: location.state.user_id } });
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  

  console.log('location.state.user_id', location.state.user_id)

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      await dispatch(resendOTP({ id: location.state.user_id })).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const currentLanguage = i18n.language;

  return (
    <>
    <section class="authentication-bg">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong border-0 rounded" >
          <div class="card-body py-5 px-md-5 px-4 text-center">

          <img onClick={()=>navigate("/")} className="mx-auto d-block auth-logo mb-5 wf-logo-card" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-arabic.png' : 'logo.svg'}`} alt="Shadi Tayari" width={currentLanguage === 'ar' ? '240px' : '270px'} />
          <img className="mb-3" src={"../assets/images/otp-verification.png"} />
          <p className="dm-font font-size-20 pink-color mb-0">
          {t('WeJustSentYouAVerificationEmail')}
            </p>
            <p className="font-size-12 color-black mb-4">
            {t('PleaseenteryourOTPthatyouhaverecivethroughemail')}
            </p>
            <div className="custom-otp-input mb-4">
            
              <input
                type="text"
                maxLength={6}
                onChange={(e) => setOTP(e.target.value)}
                onKeyPress={handleKeyPress}
              />    
         
          </div>
          <div class="d-grid gap-2">
              <button class="btn wf-purple-btn wf-btn btn-lg" onClick={handleClick}>{t('Verify')}</button>
            </div>
          </div>
          <div class="card-footer wf-auth-card-footer">
            <div className="text-center">
            <a
            className=""
            onClick={handleResend}
          >
            {t('DDidntreceivethecode')}? <b className="pink-color">{t('Resend')}</b>
          </a>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
<p className="d-none"> <LanguageSwitcher /> </p>
    </>
  );
};

export default SignUp1;