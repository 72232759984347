import React, { useEffect, useState } from 'react'
import NavVendor from '../../components/Layout/NavVendor'
import Login from '../Authentication/Login'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getServices } from '../../store/slices/userSlice';

const Bussinespage = () => {
  const [services, setServices] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Services = async () => {
    try {
      setServices(null);
      const response = await dispatch(getServices()).unwrap();
      setServices(response?.data);
    } catch (rejectedValueOrSerializedError) {}
  };
  useEffect(() => {
        Services();
  }, []);

  return (
    <>
      <NavVendor fullwidth="No" />
      <section className="business-section-1 py-5 number-one-picks-bg">
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-8 col-md-12">
              <h2 className="font-size-36 color-white dm-font mb-4">Transform Your Wedding Business with Shadi Tayari</h2>
              <ul className='wf-business-ul font-size-18 d-flex flex-column gap-3 ps-0 pe-5 color-white mb-4'>
                <li>Expand your visibility to a targeted audience in the Middle East using our user-friendly platform.</li>
                <li>Enhance operations with robust marketing support and streamlined booking processes.</li>
                <li>Utilize real-time analytics for optimizing services based on customer preferences.</li>
                <li>Benefit from a cost-effective model with no upfront fees, paying commissions only on actual bookings.</li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className='wf-shadow-2 white-bg p-3 p-md-4 w-100 rounded mb-3'>
                <h2 className="font-size-30 dm-font fw-normal text-center color-black mb-2">Business login</h2>
                <Login />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section2 */}
      <section className="business-section-2 py-5">
        <div className="container">
          <h2 className="font-size-32 playfair-font mb-4 w-75 mx-auto color-black text-center fw-normal">Why List with <span className='gradient-color'>Shadi Tayari?</span></h2>
          <p className="font-size-18 color-black mb-5 w-75 mx-auto text-center fw-normal">Shadi Tayari leverages sophisticated marketing tools and algorithms to enhance visibility for vendors, effectively connecting them with their ideal client base.</p>
          <div className="row mb-4 pt-3 gy-5 align-items-center">
            <div className="col-lg-6">
              <img src="https://weddified.com/assets/images/task-image-1.png" className="features-img w-100" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="d-flex gap-3 mb-4">
                <img height="45" src="https://weddified.com/assets/images/task-icon-1.png" className="features-icon" alt="" />
                <div>
                  <h2 className="font-size-20 fw-semibold purple-color mb-2">Increased Visibility</h2>
                  <p className="font-size-16 fw-normal color-black mb-0">Gain exposure in a competitive market by showcasing your services to a targeted audience actively seeking wedding solutions.</p>
                </div>
              </div>
              <div className="d-flex gap-3 mb-4">
                <img height="45" src="https://weddified.com/assets/images/task-icon-1.png" className="features-icon" alt="" />
                <div>
                  <h2 className="font-size-20 fw-semibold pink-color mb-2">Streamlined Bookings</h2>
                  <p className="font-size-16 fw-normal color-black mb-0">Manage bookings, availabilities, and customer interactions through our user-friendly online dashboard.</p>
                </div>
              </div>
              <div className="d-flex gap-3 mb-4">
                <img height="45" src="https://weddified.com/assets/images/task-icon-1.png" className="features-icon" alt="" />
                <div>
                  <h2 className="font-size-20 fw-semibold purple-color mb-2">Market Insights</h2>
                  <p className="font-size-16 fw-normal color-black mb-0">Access real-time analytics to understand market trends, customer preferences, and optimize your offerings.</p>
                </div>
              </div>
              <div className="d-flex gap-3 mb-0">
                <img height="45" src="https://weddified.com/assets/images/task-icon-1.png" className="features-icon" alt="" />
                <div>
                  <h2 className="font-size-20 fw-semibold pink-color mb-2">Support and Growth</h2>
                  <p className="font-size-16 fw-normal color-black mb-0">Benefit from our dedicated support team and comprehensive marketing strategies designed to promote your services.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section3 */}
      <section className="business-section-3 py-5">
        <div className="container">
          <div className="white-bg wf-shadow p-4 p-md-5  rounded-4">
            <h2 className="font-size-32 playfair-font mb-4 w-75 mx-auto color-black text-center fw-normal">Management Tools for <span className='gradient-color'>Suppliers</span> & <span className='gradient-color'>Venues</span></h2>
            <p className="font-size-18 color-black mb-5 w-75 mx-auto text-center fw-normal">Shadi Tayari provides robust analytics and reporting tools that allow vendors and event planners to track performance and optimize their services:</p>
            <div className="row gy-5 mb-4 pt-3 justify-content-center">
              <div className="col-md-6 col-lg-4 text-center">
                <img height="45" src="https://weddified.com/assets/images/task-icon-1.png" className="features-icon" alt="" />
                <h2 className="font-size-20 fw-semibold purple-color mb-2">Increased Visibility</h2>
                <p className="font-size-16 fw-normal color-black mb-0">Gain exposure in a competitive market by showcasing your services to a targeted audience actively seeking wedding solutions.</p>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <img height="45" src="https://weddified.com/assets/images/task-icon-1.png" className="features-icon" alt="" />
                <h2 className="font-size-20 fw-semibold pink-color mb-2">Streamlined Bookings</h2>
                <p className="font-size-16 fw-normal color-black mb-0">Manage bookings, availabilities, and customer interactions through our user-friendly online dashboard.</p>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <img height="45" src="https://weddified.com/assets/images/task-icon-1.png" className="features-icon" alt="" />
                <h2 className="font-size-20 fw-semibold purple-color mb-2">Market Insights</h2>
                <p className="font-size-16 fw-normal color-black mb-0">Access real-time analytics to understand market trends, customer preferences, and optimize your offerings.</p>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <img height="45" src="https://weddified.com/assets/images/task-icon-1.png" className="features-icon" alt="" />
                <h2 className="font-size-20 fw-semibold pink-color mb-2">Support and Growth</h2>
                <p className="font-size-16 fw-normal color-black mb-0">Benefit from our dedicated support team and comprehensive marketing strategies designed to promote your services.</p>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <img height="45" src="https://weddified.com/assets/images/task-icon-1.png" className="features-icon" alt="" />
                <h2 className="font-size-20 fw-semibold purple-color mb-2">Market Insights</h2>
                <p className="font-size-16 fw-normal color-black mb-0">Access real-time analytics to understand market trends, customer preferences, and optimize your offerings.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section4 */}
      <section className="business-section-4 py-5">
        <div className="container">
          <h2 className="font-size-32 playfair-font mb-4 w-75 mx-auto color-black text-center fw-normal">No Fee - Minimal Commission Structure</h2>
          <div className="row mb-4 pt-3 gy-5">
            <div className="col-lg-6 d-flex align-self-stretch">
              <div className='d-flex wf-shadow-2 white-bg rounded mb-3'>
                <div className="row align-self-stretch g-0">
                  <div className='col-md-9 p-4 order-2 order-md-1'>
                    <h2 className="font-size-20 fw-semibold purple-color mb-2">Wedding Suppliers</h2>
                    <p className="font-size-16 fw-normal color-black mb-0">A competitive commission fee of 2.5% on all bookings made through the platform. This low fee ensures that you maximize your earnings while benefiting from increased exposure and booking management tools.</p>
                  </div>
                  <div className="col-md-3 order-1 order-md-2">
                    <img className="wf-businesspage_nofeeimg img-fluid h-100 object-fit-cover" alt='' src="https://images.weddified.com/images/00.54935470655420229C6CFB0D-F227-48AF-B22E-300BEE5EDAA5.jpeg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-self-stretch">
              <div className='d-flex wf-shadow-2 white-bg rounded mb-3'>
                <div className="row align-self-stretch g-0">
                  <div className='col-md-9 p-4 order-2 order-md-1'>
                    <h2 className="font-size-20 fw-semibold pink-color mb-2">Venues</h2>
                    <p className="font-size-16 fw-normal color-black mb-0">A commission fee of 5% on all bookings, reflecting the larger ticket size and extended services typically required for venue rentals. This fee covers extensive marketing efforts and the use of advanced tools to showcase your venue spectacularly.</p>
                  </div>
                  <div className="col-md-3 order-1 order-md-2">
                    <img className="wf-businesspage_nofeeimg img-fluid h-100 object-fit-cover" alt='' src="https://images.weddified.com/images/00.54935470655420229C6CFB0D-F227-48AF-B22E-300BEE5EDAA5.jpeg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section5 */}
      <section className="business-section-1 py-5 number-one-picks-bg">
        <div className="container">
          <div className="white-bg wf-shadow p-4 p-md-5 mb-5 rounded-4">
            <h2 className="font-size-36 playfair-font mb-4 w-75 mx-auto color-black text-center fw-normal">Ready to grow your business with <span className='gradient-color'>Shadi Tayari?</span></h2>
            <p className="font-size-18 color-black mb-5 w-75 mx-auto text-center fw-normal">Select your business type to start your journey with Shadi Tayari,<br/> where opportunities bloom and your business grows.</p>
            <div class="d-flex gap-2 flex-wrap mb-4">
          {services?.length>0&&services?.map((s)=>(
          <>
          <input type="radio" name="cat-select" value={s._id} id={s?._id} class="btn-check" onChange={()=> navigate("/signup" ,{state:{data:s}})} />
          <label key={s._id} class="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2" style={{width:'15%', lineHeight:'1.2'}} for={s?._id}>
            <img src={`${process.env.REACT_APP_IMGURL}${s.imageName}`} className="mx-auto d-block color" height="35" />
            <img src={`${process.env.REACT_APP_IMGURL}white-${s.imageName}`} className="mx-auto d-block white" height="35" />
            {s?.name}</label>
          </>
          ))}
        </div>
          </div>
        </div>
      </section>
      
    </>
  )
}

export default Bussinespage
