import React, { useRef } from 'react';
import Sidebar from "../../components/Layout/SidebarVendor";
import StoreNavBar from "./StoreNavBar";
import { useTranslation } from 'react-i18next';

const ShadiTayariBadge = () => {
  const { t } = useTranslation();
  const svgRef = useRef(null);

  const copySvgToClipboard = () => {
    const svgElement = svgRef.current;
    if (svgElement) {
      const svgCode = new XMLSerializer().serializeToString(svgElement);

      // Create a temporary textarea to hold the HTML content
      const textArea = document.createElement('textarea');
      textArea.value = svgCode;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      alert('SVG code copied as HTML to clipboard');
    }
  };
  return (
    <>
      <section className="wf-vendor-contact-details">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* Heading */}
              <h2 class="font-size-30 dm-font purple-color mb-4 fw-normal">Shadi Tayari Badge</h2>
              {/* <StoreNavBar /> */}
              <div className="wf-shadow-2 white-bg p-4 mb-4 rounded">
                <p className="font-size-20 black-color mb-0 text-capitalize dm-font fw-normal">Add Shadi Tayari to your website</p>
                <p className="font-size-16 fw-medium black-color">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable.</p>
                <div className="d-flex flex-md-row flex-column align-items-center" ref={svgRef}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="" width="180" height="105" viewBox="0 0 180 105">
                    <defs>
                      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#804199" />
                        <stop offset="1" stop-color="#ee0e7b" />
                      </linearGradient>
                      <filter id="Rectangle_1794" x="19.5" y="0" width="148" height="111" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood flood-opacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <filter id="FIND_US_ON_SHADI_TAYARI" x="0" y="31.5" width="201" height="116" filterUnits="userSpaceOnUse">
                        <feOffset dx="5" dy="5" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="17.5" result="blur-2" />
                        <feFlood flood-opacity="0.2" />
                        <feComposite operator="in" in2="blur-2" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g id="Group_4434" data-name="Group 4434" transform="translate(-405.892 -628)">
                      <g transform="matrix(1, 0, 0, 1, 405.89, 628)" filter="url(#Rectangle_1794)">
                        <rect id="Rectangle_1794-2" data-name="Rectangle 1794" width="130" height="93" rx="6" transform="translate(28.5 6)" fill="url(#linear-gradient)" />
                      </g>
                      <g id="Group_4190" data-name="Group 4190" transform="translate(458.517 642)">
                        <g id="Group_4188" data-name="Group 4188" transform="translate(0 42.307)">
                          <path id="Path_1" data-name="Path 1" d="M136.916-142.387v.321q0,.522.05,1.215a5.255,5.255,0,0,0,.261,1.315,2.737,2.737,0,0,0,.632,1.054,1.525,1.525,0,0,0,1.144.432,3.187,3.187,0,0,0,1.506-.381,3.1,3.1,0,0,0,1.144-1l.141.1a3.247,3.247,0,0,1-1.235,1.185,3.68,3.68,0,0,1-1.877.482,4.519,4.519,0,0,1-1.656-.3,4.176,4.176,0,0,1-1.345-.833,3.933,3.933,0,0,1-.9-1.255,3.761,3.761,0,0,1-.331-1.586,4.1,4.1,0,0,1,.271-1.486,3.582,3.582,0,0,1,.793-1.234,3.767,3.767,0,0,1,1.285-.843,4.594,4.594,0,0,1,1.746-.311,4.227,4.227,0,0,1,1.857.4,2.3,2.3,0,0,1,1.175,1.245,3.819,3.819,0,0,1,.321,1.486Zm2.73-.2q-.04-.4-.09-.883a5.052,5.052,0,0,0-.171-.893,2.037,2.037,0,0,0-.341-.693.726.726,0,0,0-.6-.281,1.205,1.205,0,0,0-.743.211,1.48,1.48,0,0,0-.452.582,3.121,3.121,0,0,0-.241.873,10.343,10.343,0,0,0-.09,1.084Z" transform="translate(-115.477 151.803)" fill="#fff" />
                          <path id="Path_2" data-name="Path 2" d="M183.379-162.645v-1a1.886,1.886,0,0,1-.472.391,6.119,6.119,0,0,1-.723.392,6.034,6.034,0,0,1-.793.3,2.488,2.488,0,0,1-.7.12,3.1,3.1,0,0,1-1.365-.291,3.335,3.335,0,0,1-1.044-.783,3.392,3.392,0,0,1-.673-1.134,4,4,0,0,1-.231-1.365,4.731,4.731,0,0,1,.271-1.576,4.412,4.412,0,0,1,.773-1.375,3.958,3.958,0,0,1,1.2-.974,3.3,3.3,0,0,1,1.566-.372,3.092,3.092,0,0,1,1.285.281,3.475,3.475,0,0,1,.9.542v-6.043a1.05,1.05,0,0,0-.11-.562.547.547,0,0,0-.472-.16h-.743v-.2h3.734v13.611h1.044v.2Zm0-6.645a2.608,2.608,0,0,0-.633-.452,1.571,1.571,0,0,0-.693-.151,1.445,1.445,0,0,0-.673.2,2.362,2.362,0,0,0-.7.612,3.468,3.468,0,0,0-.552,1.054,4.779,4.779,0,0,0-.221,1.546,5.194,5.194,0,0,0,.151,1.265,4,4,0,0,0,.422,1.064,2.447,2.447,0,0,0,.632.733,1.252,1.252,0,0,0,.763.271,1.863,1.863,0,0,0,.843-.2,2.116,2.116,0,0,0,.663-.5Z" transform="translate(-149.854 176.577)" fill="#fff" />
                          <path id="Path_3" data-name="Path 3" d="M234.371-162.645v-1a1.882,1.882,0,0,1-.472.391,6.119,6.119,0,0,1-.723.392,6.033,6.033,0,0,1-.793.3,2.488,2.488,0,0,1-.7.12,3.1,3.1,0,0,1-1.365-.291,3.337,3.337,0,0,1-1.044-.783,3.393,3.393,0,0,1-.673-1.134,4,4,0,0,1-.231-1.365,4.735,4.735,0,0,1,.271-1.576,4.407,4.407,0,0,1,.773-1.375,3.954,3.954,0,0,1,1.2-.974,3.3,3.3,0,0,1,1.566-.372,3.091,3.091,0,0,1,1.285.281,3.471,3.471,0,0,1,.9.542v-6.043a1.05,1.05,0,0,0-.11-.562.547.547,0,0,0-.472-.16h-.743v-.2h3.734v13.611h1.044v.2Zm0-6.645a2.608,2.608,0,0,0-.633-.452,1.571,1.571,0,0,0-.693-.151,1.445,1.445,0,0,0-.673.2,2.361,2.361,0,0,0-.7.612,3.469,3.469,0,0,0-.552,1.054,4.78,4.78,0,0,0-.221,1.546,5.194,5.194,0,0,0,.151,1.265,4.009,4.009,0,0,0,.422,1.064,2.447,2.447,0,0,0,.632.733,1.252,1.252,0,0,0,.763.271,1.863,1.863,0,0,0,.843-.2,2.116,2.116,0,0,0,.663-.5Z" transform="translate(-190.688 176.577)" fill="#fff" />
                          <path id="Path_4" data-name="Path 4" d="M279.461-137.063v-.2h.5a.5.5,0,0,0,.371-.1.765.765,0,0,0,.09-.462v-5.6a2.75,2.75,0,0,0-.06-.642q-.06-.241-.462-.241h-.442v-.2h3.373v7.247h.884v.2Z" transform="translate(-231.603 150.995)" fill="#fff" />
                          <path id="Path_5" data-name="Path 5" d="M311.355-175.516a.994.994,0,0,1-.311.743,1,1,0,0,1-.713.3.982.982,0,0,1-.662-.291.9.9,0,0,1-.321-.693,1.02,1.02,0,0,1,.211-.582,2.515,2.515,0,0,1,.371-.441.7.7,0,0,0-.462-.351c-.2-.047.043-.07-.1-.07a.955.955,0,0,0-.775.361,2.591,2.591,0,0,0-.643.924,5.275,5.275,0,0,0-.351,1.265,8.751,8.751,0,0,0-.11,1.365v2.409h1.706v.2h-1.165q-.361,0-.452.141a.941.941,0,0,0-.09.482v6.424h1.526v.2h-4.959v-.2h.542a.705.705,0,0,0,.412-.07.707.707,0,0,0,.07-.412v-7.83a6.947,6.947,0,0,1,.281-1.937,5.685,5.685,0,0,1,.833-1.747,4.4,4.4,0,0,1,1.375-1.255,3.707,3.707,0,0,1,1.907-.482c.241,0-.118.023.176.07a3.033,3.033,0,0,1,.823.251,1.831,1.831,0,0,1,.632.482A1.124,1.124,0,0,1,311.355-175.516Z" transform="translate(-251.295 177.062)" fill="#fff" />
                          <path id="Path_6" data-name="Path 6" d="M360.938-142.387v.321q0,.522.05,1.215a5.268,5.268,0,0,0,.261,1.315,2.74,2.74,0,0,0,.632,1.054,1.525,1.525,0,0,0,1.144.432,3.186,3.186,0,0,0,1.506-.381,3.1,3.1,0,0,0,1.144-1l.141.1a3.249,3.249,0,0,1-1.235,1.185,3.68,3.68,0,0,1-1.877.482,4.519,4.519,0,0,1-1.656-.3,4.175,4.175,0,0,1-1.345-.833,3.932,3.932,0,0,1-.9-1.255,3.761,3.761,0,0,1-.331-1.586,4.1,4.1,0,0,1,.271-1.486,3.582,3.582,0,0,1,.793-1.234,3.766,3.766,0,0,1,1.285-.843,4.594,4.594,0,0,1,1.746-.311,4.226,4.226,0,0,1,1.857.4,2.3,2.3,0,0,1,1.175,1.245,3.818,3.818,0,0,1,.321,1.486Zm2.73-.2q-.04-.4-.09-.883a5.043,5.043,0,0,0-.171-.893,2.036,2.036,0,0,0-.341-.693.726.726,0,0,0-.6-.281,1.206,1.206,0,0,0-.743.211,1.48,1.48,0,0,0-.452.582,3.128,3.128,0,0,0-.241.873,10.335,10.335,0,0,0-.09,1.084Z" transform="translate(-294.871 151.803)" fill="#fff" />
                          <path id="Path_7" data-name="Path 7" d="M407.4-162.645v-1a1.884,1.884,0,0,1-.472.391,6.119,6.119,0,0,1-.723.392,6.033,6.033,0,0,1-.793.3,2.488,2.488,0,0,1-.7.12,3.1,3.1,0,0,1-1.365-.291,3.335,3.335,0,0,1-1.044-.783,3.394,3.394,0,0,1-.672-1.134,4.005,4.005,0,0,1-.231-1.365,4.737,4.737,0,0,1,.271-1.576,4.408,4.408,0,0,1,.773-1.375,3.954,3.954,0,0,1,1.2-.974,3.3,3.3,0,0,1,1.566-.372,3.091,3.091,0,0,1,1.285.281,3.471,3.471,0,0,1,.9.542v-6.043a1.05,1.05,0,0,0-.11-.562.547.547,0,0,0-.472-.16h-.743v-.2h3.734v13.611h1.044v.2Zm0-6.645a2.609,2.609,0,0,0-.632-.452,1.571,1.571,0,0,0-.693-.151,1.445,1.445,0,0,0-.672.2,2.361,2.361,0,0,0-.7.612,3.471,3.471,0,0,0-.552,1.054,4.779,4.779,0,0,0-.221,1.546,5.2,5.2,0,0,0,.151,1.265,4.011,4.011,0,0,0,.422,1.064,2.446,2.446,0,0,0,.632.733,1.252,1.252,0,0,0,.763.271,1.863,1.863,0,0,0,.843-.2,2.115,2.115,0,0,0,.663-.5Z" transform="translate(-329.248 176.577)" fill="#fff" />
                          <path id="Path_41" data-name="Path 41" d="M65-128.593l.025.049A.045.045,0,0,1,65-128.593Z" transform="translate(-59.861 138.249)" fill="#fff" />
                          <path id="Path_42" data-name="Path 42" d="M65.021-128.544a.045.045,0,0,1-.025-.049Z" transform="translate(-59.861 138.249)" fill="#fff" />
                          <path id="Path_43" data-name="Path 43" d="M104.185-176.436h-3.9l-4.025,8-2.3-1.885,3.335,7,.376.815,0-.007,0,.007,0-.007.01.02.648-1.427,2.687-5.741,3.171-6.779Z" transform="translate(-83.053 176.561)" fill="#fff" />
                          <path id="Path_44" data-name="Path 44" d="M41.241-172.721a3.768,3.768,0,0,0,.077.761c-.431-.907-2.1-4.476-2.1-4.476l5.175.018A3.744,3.744,0,0,0,41.241-172.721Z" transform="translate(-39.222 176.561)" fill="#fff" />
                          <path id="Path_45" data-name="Path 45" d="M64.1-176.436h-3.9l-4.025,8-2.3-1.885,3.335,7,.376.815,0-.007,0,.007,0-.007.01.02.648-1.427,2.687-5.741,3.171-6.779Z" transform="translate(-50.951 176.561)" fill="#fff" />
                          <path id="Path_46" data-name="Path 46" d="M341.554-159.8a1.3,1.3,0,0,1-.391.954,1.3,1.3,0,0,1-.954.392,1.321,1.321,0,0,1-.974-.392,1.3,1.3,0,0,1-.391-.954,1.321,1.321,0,0,1,.391-.974,1.322,1.322,0,0,1,.974-.392,1.3,1.3,0,0,1,.954.392A1.322,1.322,0,0,1,341.554-159.8Z" transform="translate(-279.156 164.332)" fill="#fff" />
                          <path id="Path_47" data-name="Path 47" d="M334.266-137.063v-.2h.5a.5.5,0,0,0,.372-.1.768.768,0,0,0,.09-.462v-5.6a2.757,2.757,0,0,0-.06-.642q-.06-.241-.462-.241h-.442v-.2h3.373v7.247h.883v.2Z" transform="translate(-275.49 150.995)" fill="#fff" />
                          <path id="Path_48" data-name="Path 48" d="M287.654-159.8a1.3,1.3,0,0,1-.391.954,1.3,1.3,0,0,1-.954.392,1.321,1.321,0,0,1-.974-.392,1.3,1.3,0,0,1-.392-.954,1.321,1.321,0,0,1,.392-.974,1.321,1.321,0,0,1,.974-.392,1.3,1.3,0,0,1,.954.392A1.322,1.322,0,0,1,287.654-159.8Z" transform="translate(-235.993 164.332)" fill="#fff" />
                        </g>
                        <g id="Group_4189" data-name="Group 4189" transform="translate(12.258)">
                          <path id="Path_4447" data-name="Path 4447" d="M121.177-176.436H110.789l-10.712,21.3-6.121-5.016,8.874,18.628,1,2.168.009-.018.009.02.006-.018.027.054,1.725-3.8,7.149-15.278,8.439-18.039Z" transform="translate(-64.941 176.436)" fill="#fff" />
                          <path id="Path_4448" data-name="Path 4448" d="M44.6-166.549a10.027,10.027,0,0,0,.206,2.024c-1.147-2.415-5.58-11.911-5.58-11.911l13.77.048A9.963,9.963,0,0,0,44.6-166.549Z" transform="translate(-39.222 176.436)" fill="#fff" />
                          <path id="Path_4449" data-name="Path 4449" d="M81.089-176.436H70.7l-10.712,21.3-6.121-5.016,8.874,18.628,1,2.168.009-.018.009.02.006-.018.027.054,1.725-3.8L72.669-158.4l8.439-18.039Z" transform="translate(-46.104 176.436)" fill="#fff" />
                        </g>
                      </g>
                      <g transform="matrix(1, 0, 0, 1, 405.89, 628)" filter="url(#FIND_US_ON_SHADI_TAYARI)">
                        <text id="FIND_US_ON_SHADI_TAYARI-2" data-name="FIND US ON SHADI TAYARI" transform="translate(47.5 88)" fill="#fff" font-size="9" font-family="Roboto-Regular, Roboto">
                          <tspan x="0" y="0">FIND US ON SHADI TAYARI</tspan>
                        </text>
                      </g>
                    </g>
                  </svg>
                  <div className="text-center mt-3 mt-md-0 text-md-start">
                    <button onClick={copySvgToClipboard} class="btn btn-lg wf-pink-btn wf-btn fw-medium text-capitalize py-2">Get your HTML Code</button>
                    <p className="font-size-16 pink-color mb-0 text-capitalize cursor-pointer mt-3 fw-medium">How to add the Shadi Tayari badge</p>
                  </div>
                </div>
              </div>

            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default ShadiTayariBadge