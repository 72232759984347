import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { X } from "lucide-react";
import {
  getEventList, editStoreFront
} from "../../store/slices/userSlice";
import Sidebar from "../../components/Layout/SidebarVendor";
import StoreNavBar from './StoreNavBar'
import { useTranslation } from "react-i18next";
const PhotoGallery = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myEvents = useSelector(getEventList);
  const navigate = useNavigate();
  const location = useLocation();


  const [dp, setdp] = useState("")

  const [images, setImages] = useState([]);
  const [prev_images, setprev_images] = useState([]);
  const [video, setvideo] = useState([])
  const [newVideoLink, setnewVideoLink] = useState("")
  const [toggle, settoggle] = useState([]);
  const [load, setload] = useState(false);



  const handleImageUpload = (event) => {

    const selectedImages = event.target.files;
    const imagesArray = [];

    for (let i = 0; i < selectedImages.length; i++) {
      imagesArray.push(selectedImages[i]);
    }
    setImages(images.concat(imagesArray));
  };

  const handleImageRemovePrev = (e, index) => {
    e.preventDefault();
    const updatedImages = [...prev_images];
    updatedImages.splice(index, 1);
    setprev_images(updatedImages);
  };

  const handleImageRemove = (e, index) => {
    e.preventDefault();
    const updatedImages = [...images];
    updatedImages.splice(index, 1);

    setImages(updatedImages);
  };


  const handleToggleChange = (id) => {
    console.log('idddddddddddddddd', id);
    if (!toggle.includes(id)) {
      settoggle([...toggle, id]);
    } else {
      const updatedEventIds = toggle.filter((itemId) => itemId !== id);
      settoggle(updatedEventIds);
    }
  };



  const handleSave = async (e) => {
    e.preventDefault();



    try {

      setload(true)
      const data = new FormData();

      data.append("image", dp);
      data.append("_id", myEvents?._id);
      data.append("path", location.pathname);
      data.append("video", JSON.stringify(video));
      data.append("prev_picture", JSON.stringify(prev_images));

      images.forEach((item) => {

        data.append("imageName", item);

      });

      const nav = await dispatch(
        editStoreFront(data)
      ).unwrap();

      setload(false)

      if (nav) {
        navigate("/social-network")
      }



    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {


    if (myEvents) {

      setprev_images(myEvents?.imageName?.length > 0 ? myEvents?.imageName : [])
      setdp(myEvents?.image);
      setvideo(myEvents?.video);
    }


  }, [myEvents]);


  return (
    <>
      <section className="wf-vendor-contact-details">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* <StoreNavBar /> */}
              {/* <div className="d-flex gap-3 align-items-center mb-4">
                  <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/photos-videos-icon.svg`} alt="" className="events-icon" />
                  
                </div> */}
              <h2 className="font-size-30 dm-font purple-color mb-4 fw-normal">{t('Photos')}</h2>
              <div className="wf-shadow-2 white-bg p-4 rounded mb-4">
                <div className="upload__img-wrap">
                  {prev_images?.length > 0 && prev_images.map((image, index) => (


                    <div className="upload__img-box" key={index}>
                      <div style={{ backgroundImage: `url()` }} className="img-bg">

                        <img
                          src={`${process.env.REACT_APP_IMGURL}${image}`}
                          alt={`Store Image`}
                          className="img-bg-size"
                        />

                        <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                        <div className="upload__img-profile">
                          <div class="dropdown wf-featured-dropdown">
                            <a class="" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <i onClick={() => handleToggleChange(image)} class="bi bi-chevron-double-down"></i></a>
                            <div class="dropdown-menu" >
                              <div class="form-check">
                                <input checked={dp == image ? true : false} onChange={() => setdp(image)} class="form-check-input" type="radio" name="dp" id="dp" />
                                <label class="form-check-label" for="dp">Featured Image</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {images?.length > 0 && images.map((image, index) => (
                    <div className="upload__img-box" key={index}>
                      <div style={{ backgroundImage: `url()` }} className="img-bg">

                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Store Image`}
                          className="img-bg-size"
                        />

                        <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                        <div className="upload__img-profile">
                          <div class="dropdown wf-featured-dropdown">
                            <a class="" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <i onClick={() => handleToggleChange(image)} class="bi bi-chevron-double-down"></i></a>
                            <div class="dropdown-menu">
                              <div class="form-check">
                                <input checked={dp == image.name ? true : false} onChange={() => setdp(image.name)} class="form-check-input" type="radio" name="dp" id="dp" />
                                <label class="form-check-label" for="dp">Featured Image</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <input type="file" class="btn-check d-none" id="btn-check" multiple data-max_length="20" onChange={handleImageUpload} />
                  <label class="btn wf-btn wf-pink-btn px-4 fw-bold py-2 text-uppercase" for="btn-check">{t('Add')} {t('Photos')}</label>
                </div>
              </div>

              <h2 className="font-size-30 dm-font purple-color mb-4 fw-normal">{t('Videos')}</h2>
              <div className="wf-shadow-2 white-bg p-4 rounded mb-4">
                {/* <div className="d-flex gap-3 align-items-center mb-4">
                  <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/photos-videos-icon.svg`} alt="" className="events-icon" />
                  <h2 className="font-size-36 playfair-font color-black mb-0">{t('Videos')}</h2>
                </div> */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    {/* <div class="form-floating wf-input wf-input-2">
      <input type="url" class="form-control" id="video-link" placeholder="link" 
       onChange={(e)=>setvideo(e.target.value)} defaultValue={video} autocomplete="link" required />
      <label for="video-link" className="text-uppercase">Youtube/Vimeo Video Link</label>
    </div> */}
                    <div class="form-floating wf-input wf-input-101">
                      <input type="url" class="form-control" id="video-link" placeholder="link"
                        value={newVideoLink} onChange={(e) => setnewVideoLink(e.target.value)} autocomplete="link" required />
                      <label for="video-link" className="text-uppercase">Youtube/Vimeo Video Link</label>
                    </div>
                  </div>
                  <div className="col-md-2 align-self-center">
                    {newVideoLink !== "" && (<button onClick={() => { setvideo([...video, newVideoLink]); setnewVideoLink("") }} class="btn wf-btn wf-pink-btn px-4 fw-bold py-2 text-uppercase" >Add Video</button>)}
                  </div>
                </div>

                <div className="row">
                  {video?.map((videoLink, index) => (
                    <div className="col-md-4" key={index}>

                      <X className="cursor-pointer" onClick={() => {
                        const updatedVideo = [...video];
                        updatedVideo.splice(index, 1);
                        setvideo(updatedVideo);
                      }} />
                      <iframe title={`Video Player ${index + 1}`} width="100%" height="200" src={`https://www.youtube.com/embed/${videoLink?.split('v=')[1]}`} frameBorder="0" allowFullScreen></iframe>
                    </div>
                  ))}
                </div>


                {images?.length < 1 && prev_images?.length < 1 ? (null)
                  : (
                    <div className="text-end">
                      <button className="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2" onClick={handleSave}>
  {load ? (
    <div className="spinner-border wf-spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  ) : (
    t('Save')
  )}
</button>

                    </div>
                  )}

              </div>
            </section>
          </div>
        </div>
      </section>


    </>
  );
};

export default PhotoGallery;