import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getEventList, editStoreFront
} from "../../store/slices/userSlice";
import Sidebar from "../../components/Layout/SidebarVendor";
import StoreNavBar from "./StoreNavBar";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


const Deals = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myEvents = useSelector(getEventList);
  const navigate = useNavigate();
  const location = useLocation();



  const [store_deal, setstore_deal] = useState([])
  const [service_name, setservice_name] = useState("")
  const [price, setprice] = useState("")
  const [pricing, setpricing] = useState("");
  const [percentage, setpercentage] = useState("none")

  const [lessthan50, setlessthan50] = useState("")
  const [from50to100, setfrom50to100] = useState("")
  const [from100to150, setfrom100to150] = useState("")
  const [from150to250, setfrom150to250] = useState("")
  const [from250to350, setfrom250to350] = useState("")
  const [from350to500, setfrom350to500] = useState("")
  const [from500to750, setfrom500to750] = useState("")
  const [from750to1000, setfrom750to1000] = useState("")
  const [morethan1000, setmorethan1000] = useState("")



  useEffect(() => {
    if (myEvents) {
      setpricing(myEvents?.prefered_services[0]?.pricing_type);
      setlessthan50(myEvents?.store_pricing?.lessthan50);
      setfrom50to100(myEvents?.store_pricing?.from50to100);
      setfrom100to150(myEvents?.store_pricing?.from100to150);
      setfrom150to250(myEvents?.store_pricing?.from150to250);
      setfrom250to350(myEvents?.store_pricing?.from250to350);
      setfrom350to500(myEvents?.store_pricing?.from350to500);
      setfrom500to750(myEvents?.store_pricing?.from500to750);
      setfrom750to1000(myEvents?.store_pricing?.from750to1000);
      setmorethan1000(myEvents?.store_pricing?.morethan1000);
      setstore_deal(myEvents?.store_deal?.data);
      setpercentage(myEvents?.discount);
    }
  }, [myEvents]);






  const handleSave = async (e) => {
    e.preventDefault();



    try {

      const requestData = {
        _id: myEvents ? myEvents._id : undefined,
        path: location.pathname,
        percentage: percentage,
        pricing: pricing === "pricing" ? "pricing" : undefined,
        ...(pricing === "pricing" && {
          lessthan50,
          from50to100,
          from100to150,
          from150to250,
          from250to350,
          from350to500,
          from500to750,
          from750to1000,
          morethan1000,
        }),
        ...(pricing === "deal" &&
          store_deal !== "undefined" &&
          store_deal !== undefined && {
          store_deal: JSON.stringify(store_deal),
        }),
      };



      const nav = await dispatch(
        editStoreFront(requestData)
      ).unwrap();

      if (nav) {
        navigate("/photos-and-videos")
      }



    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const addMoreDeal = () => {
    setstore_deal([...store_deal, { service_name: service_name, price: price }]);
    setservice_name("")
    setprice("")
  };


  const handlePricingChange = (e, i) => {
    console.log('e.target.valuee', e.target.value, i);

    if (i === 0) {
      setlessthan50(e.target.value)
    }
    if (i === 1) {
      setfrom50to100(e.target.value)
    }
    if (i === 2) {
      setfrom100to150(e.target.value)
    }
    if (i === 3) {
      setfrom150to250(e.target.value)
    }
    if (i === 4) {
      setfrom250to350(e.target.value)
    }
    if (i === 5) {
      setfrom350to500(e.target.value)
    }
    if (i === 6) {
      setfrom500to750(e.target.value)
    }
    if (i === 7) {
      setfrom750to1000(e.target.value)
    }
    if (i === 8) {
      setmorethan1000(e.target.value)
    }
  };

  const pricingArray = ["Less than 50", "From 50 to 100", "From 100 to 150", "From 150 to 250", "From 250 to 350", "From 350 to 500", "From 500 to 750", "From 750 to 1000", "More than 1000"]


  console.log('lessthan50', lessthan50)

  return (
    <>
      <section className="wf-vendor-contact-details">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* <StoreNavBar /> */}
              <h2 className="font-size-30 dm-font purple-color mb-4 fw-normal">{t('Add')} {t('SpecialDiscounts')}</h2>
              <div className="wf-shadow-2 white-bg p-4 rounded mb-4">
                <p className="font-size-20 dm-font color-black mb-2">Exclusive discount for Shadi Tayari Couples</p>
                <div class="d-flex gap-2 flex-wrap">
                  <input type="radio" name="discount" class="btn-check" id="none" value="0" checked={percentage == 'none' || percentage == 0 ? true : false} onChange={() => setpercentage("none")} />
                  <label class="btn wf-select-btn px-3" for="none">none</label>
                  <input type="radio" name="discount" class="btn-check" id="5%" value="5" checked={percentage == '5' ? true : false} onChange={() => setpercentage(5)} />
                  <label class="btn wf-select-btn px-3" for="5%">5%</label>
                  <input type="radio" name="discount" class="btn-check" id="10%" value="10" checked={percentage == '10' ? true : false} onChange={() => setpercentage(10)} />
                  <label class="btn wf-select-btn px-3" for="10%">10%</label>
                  <input type="radio" name="discount" class="btn-check" id="15%" value="15" checked={percentage == '15' ? true : false} onChange={() => setpercentage(15)} />
                  <label class="btn wf-select-btn px-3" for="15%">15%</label>
                  <input type="radio" name="discount" class="btn-check" id="20%" value="20" checked={percentage == '20' ? true : false} onChange={() => setpercentage(20)} />
                  <label class="btn wf-select-btn px-3" for="20%">20%</label>
                  <input type="radio" name="discount" class="btn-check" id="other" value="other" checked={!percentage == "0" && !percentage === "5" && !percentage === "10" && !percentage === "15" && !percentage === "20" ? true : false} onChange={(e) => setpercentage(e.target.value)} />
                  <label class="btn wf-select-btn px-3" for="other">other</label>
                  {percentage !== 0 && percentage !== "none" && percentage !== 5 && percentage !== 10 && percentage !== 15 && percentage !== 20 ? (
                    <input class="form-control form-control-sm wf-input-sm py-2 w-auto wf-shadow-2-2" type="number" defaultValue={percentage}
                      placeholder="%" required onChange={(e) => setpercentage(e.target.value)} />
                  ) : null}
                </div>
                <div className="text-end">
                  <button onClick={handleSave} class="btn btn-lg wf-pink-btn wf-btn fw-bold text-uppercase py-2">{t('Save')}</button>
                </div>
              </div>
              {pricing == "pricing" || pricing == "deal" && (
                <div className="wf-shadow-2 white-bg p-4 rounded mb-4">
                  {/* <div className="d-flex gap-3 align-items-center mb-4">
                  <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/pricing-deals-icon.svg`} alt="" className="events-icon" />
                  <h2 className="font-size-36 playfair-font color-black mb-0">{t('Pricing&Deals')}</h2>
                </div> */}
                  {pricing == "pricing" && (<div>
                    <p className="font-size-20 dm-font pink-color mb-2">{t('PricingbyGuestCount')}</p>
                    <div className="row gy-3">

                      {pricingArray?.map((p, i) => (<div className="col-md-4">
                        <>
                          <p className="font-size-16 dm-font purple-color mb-2">{p}</p>
                          <input defaultValue={i == 0 ? lessthan50 : i == 1 ? from50to100 : i == 2 ? from100to150 : i == 3 ? from150to250 : i == 4 ? from250to350 : i == 5 ? from350to500 : i == 6 ? from500to750 : i == 7 ? from750to1000 : i == 8 ? morethan1000 : ""} onChange={(e) => handlePricingChange(e, i)} className="form-control form-control-sm wf-input-sm" type="number" />
                        </>
                      </div>))}

                    </div>
                  </div>)}


                  {pricing == "deal" && (
                    <>
                      <h2 className="font-size-20 dm-font pink-color mb-2">{t('PricingbyService')}</h2>
                      <div className="row  mb-3">
                        <div className="col-md-7">
                          <div class="form-floating wf-input wf-input-101">
                            <input type="text" class="form-control" id="service-name" placeholder="Service Name" onChange={(e) => setservice_name(e.target.value)} value={service_name}
                              autocomplete="service-name" required />
                            <label for="service-name" className="text-uppercase">{t('Service')} {t('Name')}</label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div class="form-floating wf-input wf-input-101">
                            <input type="text" class="form-control" id="price" placeholder="price" onChange={(e) => setprice(e.target.value)} value={price}
                              autocomplete="price" required />
                            <label for="price" className="text-uppercase">{t('Price')}</label>
                          </div>
                        </div>
                        <div className="col-md-1 align-self-center">
                          <button class="btn wf-btn wf-pink-btn px-4 fw-bold py-2 text-uppercase" onClick={addMoreDeal}>{t('Add')}</button>
                        </div>
                      </div>
                      {store_deal?.map((data, i) => (
                        <div className="row pricing-service-list" key={i}>
                          <div className="col-md-11">
                            <div className="wf-shadow-2 white-bg rounded p-2">
                              <div className="d-flex align-items-center justify-content-between">
                                <input
                                  className="form-control font-size-14 pink-color mb-0 text-uppercase"
                                  value={data.service_name}
                                  onChange={(e) => {
                                    const updatedServiceName = [...store_deal];
                                    updatedServiceName[i].service_name = e.target.value;
                                    setstore_deal(updatedServiceName);
                                  }}
                                />
                                <input
                                  style={{ width: "30%" }}
                                  className="form-control font-size-14 purple-color mb-0 text-uppercase"
                                  value={`SAR ${data.price}`}
                                  onChange={(e) => {
                                    const updatedPrice = [...store_deal];
                                    updatedPrice[i].price = e.target.value;
                                    setstore_deal(updatedPrice);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 d-flex gap-2 font-size-20 align-items-center align-self-start">
                            <i
                              className="bi bi-trash purple-color cursor-pointer"
                              onClick={() => {
                                const updatedServiceName = [...store_deal];
                                updatedServiceName.splice(i, 1);
                                setstore_deal(updatedServiceName);
                              }}
                            ></i>
                          </div>
                        </div>
                      ))}

                    </>)}

                </div>
              )}

            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default Deals