import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import ReactApexChart from 'react-apexcharts';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {
  getEventList, graphData
} from "../../store/slices/userSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AnalyticsChart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myEvents = useSelector(getEventList);
  const [days, setdays] = useState(7)
  const [daystype, setdaystype] = useState("days")
  const [value, setvalue] = useState("views")

  const [data, setdata] = useState([])




  async function getData(id) {
    try {
      // console.log('dataaaaaaa35',daystype,days)
      // return
      const response = await dispatch(graphData({ store_id: id, value: value, days: days, type: daystype })).unwrap()
      console.log('response.data', response.data)
      setdata(response.data)
      setSeries([{ name: value, data: response.data.outputData }]);


      // const newOptions = {
      //   ...options, // Copy the current options
      //   xaxis: {
      //     ...options.xaxis,
      //     labels: {
      //       ...options.xaxis.labels,
      //       formatter: (val) => {
      //         return response.data.type == "months" 
      //           ? dayjs(val).format("MMMM")
      //           : dayjs(val).format("DD-MM-YYYY");
      //       },
      //       style: {
      //         fontSize: '8px',
      //       },
      //     },
      //   },
      // };

      // setOptions(newOptions);

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError)
    }
  }




  useEffect(() => {

    getData(myEvents?._id);

  }, [myEvents])





  const [series, setSeries] = useState([
    {
      name: "",
      data: [
        // { x: '2019-01-01', y: 1000 },
        // { x: '2019-02-01', y: 2000 },
        // { x: '2019-03-01', y: 3000 },
        // { x: '2019-04-01', y: 4000 },
        // { x: '2019-05-01', y: 5000 },
        // { x: '2019-06-01', y: 6000 },
        // { x: '2019-07-01', y: 7000 },
        // { x: '2019-08-01', y: 8000 },
        // { x: '2019-09-01', y: 9000 },
        // { x: '2019-10-01', y: 9000 },
        // { x: '2019-11-01', y: 9000 },
        // { x: '2019-12-01', y: 10000 },
      ],
    }
  ]);

  const [options, setOptions] = useState({   //days
    chart: {
      type: 'bar',
      height: 380,
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [{
            from: 0,
            to: 100,
            color: '#804199', // Set the desired color for the bars
          }],
        },
      },
    },

    xaxis: {
      type: 'category',
      labels: {
        formatter: (val) => {
          return dayjs(val).format("DD-MM-YYYY");
        },
        style: {
          fontSize: '8px', // Set the desired font size here
        },
      },
    },
    tooltip: {
      x: {
        formatter: (val) => dayjs(val).format("MMMM"),
        style: {
          fontSize: '14px', // Set the desired font size here
        },
      },
    },

  });
  const [options1, setOptions1] = useState({  //months
    chart: {
      type: 'bar',
      height: 380,
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [{
            from: 0,
            to: 100,
            color: '#804199', // Set the desired color for the bars
          }],
        },
      },
    },

    xaxis: {
      type: 'category',
      labels: {
        formatter: (val) => {

          console.log('daystypeeee:', daystype, val);
          return dayjs(val).format("MMMM");
        },
        style: {
          fontSize: '8px', // Set the desired font size here
        },
      },
    },
    tooltip: {
      x: {
        formatter: (val) => dayjs(val).format("MMMM"),
        style: {
          fontSize: '14px', // Set the desired font size here
        },
      },
    },
  });
  // dayjs.extend(quarterOfYear); // Enable the quarterOfYear plugin

  // Now you can use the quarter function
  // dayjs('2022-03-15').quarter();



  useEffect(() => {

    getData(myEvents?._id);




  }, [value, days])




  return (
    <>
      <div className="row mb-3">
        <div className="col-6 d-flex gap-3 align-items-center">
          {/* <img height="36" src="../assets/images/analytics-icon-v.svg" alt="" className="events-icon" /> */}
          <h2 className="font-size-20 playfair-font color-black mb-0">{t('Analytics')}</h2>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center gap-1 selectarrowpink">
          <select
            className="font-size-16 fw-medium bg-transparent form-select form-select-sm select-0"
            onChange={(e) => {
              console.log('e.target.value', e.target.value)
              setdays(e.target.value);
              if (e.target.value == 7 || e.target.value == 30) {
                setdaystype("days");
              } else {
                setdaystype("months");
              }
            }}
          >
            <option value={7}>{t('Last7Days')}</option>
            <option value={30}>{t('Last30Days')}</option>
            <option value={6}>{t('Last6Months')}</option>
            <option value={12}>{t('Last12Months')}</option>
          </select>
        </div>
      </div>
      <div className="wf-shadow-2 white-bg p-4 rounded wf-analytics-tab mb-3">
        <div className="d-flex">
          <div className="chart-tab">
            <ul>
              <li onClick={() => setvalue("views")} className={value == "views" && "active"}>
                <i class={`bi bi-eye ${value == "views" && "active"}`}></i>
                <div><p className="font-size-14 text-uppercase mb-0">{data?.profileCount}</p>
                  <p className="font-size-10 text-uppercase mb-0">{t('Views')}</p></div>
              </li>
              <li onClick={() => setvalue("phone views")} className={value == "phone views" && "active"}>
                <i class={`bi bi-phone ${value == "phone views" && "active"}`}></i>
                <div><p className="font-size-14 text-uppercase mb-0">{data?.phoneCount}</p>
                  <p className="font-size-10 text-uppercase mb-0">{t('Phone')} {t('Views')}</p></div>
              </li>
              <li onClick={() => setvalue("website views")} className={value == "website views" && "active"}>
                <i class={`bi bi-cursor ${value == "website views" && "active"}`}></i>
                <div><p className="font-size-14 text-uppercase mb-0">{data?.websiteCount}</p>
                  <p className="font-size-10 text-uppercase mb-0">{t('WebsiteClicks')}</p></div>
              </li>
              <li onClick={() => setvalue("leads")} className={value == "leads" && "active"}>
                <i class={`bi bi-person-circle ${value == "leads" && "active"}`}></i>
                <div><p className="font-size-14 text-uppercase mb-0">{data?.leads}</p>
                  <p className="font-size-10 text-uppercase mb-0">{t('Leads')}</p></div>
              </li>
              <li onClick={() => setvalue("bookings")} className={value == "bookings" && "active"}>
                <i class={`bi bi-calendar-week ${value == "bookings" && "active"}`}></i>
                <div><p className="font-size-14 text-uppercase mb-0">{data?.bookingsCount}</p>
                  <p className="font-size-10 text-uppercase mb-0">{t('Bookings')}</p></div>
              </li>
            </ul>
          </div>
          <div id="chart" className="w-100">
            <ReactApexChart options={daystype == "days" ? options : options1} series={series} type="bar" height={300} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AnalyticsChart