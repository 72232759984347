import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getEventList, editStoreFront
} from "../../store/slices/userSlice";
import Sidebar from "../../components/Layout/SidebarVendor";
import StoreNavBar from "./StoreNavBar";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


const FAQ = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const myEvents = useSelector(getEventList);


  // FAQS
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [faq, setFAQ] = useState([]);
  const [newfaq, setNewFAQ] = useState();
  const [type, settype] = useState("");
  const [index, setindex] = useState(null);
  const [toggle1, settoggle1] = useState([])




  useEffect(() => {
    if (myEvents) {
      setFAQ(myEvents?.faqs?.data ? myEvents?.faqs?.data : faq)
    }

  }, [myEvents]);


  const toggleFAQ = (id) => {
    if (!toggle1.includes(id)) {
      settoggle1([...toggle1, id]);
    } else {
      const updatedEventIds = toggle1.filter((itemId) => itemId !== id);
      settoggle1(updatedEventIds);
    }
  };


  const handleSave = async (check) => {

    // console.log('check', check)
    console.log('isBoxVisible', isBoxVisible)
    console.log('type', type)


    if (check !== "yes" && isBoxVisible && type !== "" && newfaq == { question: '', answer: '' }) {
      toast.error("Please fill out the required fields")
    }

    else {

      let updatedFaqs = faq


      if (type == "add") {
        updatedFaqs = [...faq, newfaq];
      }

      if (type == "edit") {
        updatedFaqs = [...faq];
        updatedFaqs[index] = newfaq;
        setFAQ(updatedFaqs);
      }



      try {

        const requestData = {
          _id: myEvents?._id,
          path: location.pathname,
          faqs: JSON.stringify(updatedFaqs)
        };

        const nav = await dispatch(
          editStoreFront(requestData)
        ).unwrap();

        if (nav) {
          if (check == "yes") {
            navigate("/pricing-and-deals")
          } else {
            setNewFAQ({ question: "", answer: "" });
            settype("")
            setindex(null)
            setIsBoxVisible(false)

          }
        }



      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }

    }


  };






  const addMoreFAQ = () => {
    setNewFAQ({ question: "", answer: "" });
    setIsBoxVisible(true)
    settype("add")
  };

  const editFAQ = (i) => {
    setNewFAQ({ question: faq[i].question, answer: faq[i].answer });
    setindex(i);
    setIsBoxVisible(true);
    settype("edit");
  };

  console.log('newfaq', newfaq)
  console.log('ii', index)

  console.log('faq', faq)

  return (
    <>
      <section className="wf-vendor-faqs">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* <StoreNavBar /> */}

              <div className="row mb-4">
                <div className="col-md-6">
                  <h2 className="font-size-30 dm-font purple-color fw-norma">{t('FAQ’s')}</h2>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <button type="button" onClick={addMoreFAQ} class="btn wf-btn wf-pink-btn px-4 fw-bold py-2">{t('Add')}</button>
                </div>
              </div>
              <div className="wf-shadow-2 white-bg p-4 rounded mb-3">
                {faq?.length > 0 && faq.map((f, i) => (
                  <div className="row faq-list mb-3">
                    <div className="col-md-11">
                      <div className="wf-shadow-2-2 white-bg rounded px-2 py-3">
                        <div onClick={() => toggleFAQ(i)} className="d-flex align-items-center justify-content-between cursor-pointer">
                          <p className="font-size-14 dm-font pink-color mb-0">{f.question}</p>
                          <div className="arrow-toggle pink-color">
                            {toggle1.includes(i) ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                          </div>
                        </div>
                        <div className={`answer ${toggle1.includes(i) && "show"}`}>
                          <p className="font-size-14 color-black mb-0">{f.answer}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1 d-flex gap-2 font-size-20 align-items-center align-self-start pt-2">
                      <i onClick={() => editFAQ(i)} class="bi bi-pencil pink-color cursor-pointer"></i>
                      <i onClick={() => { const updatedFAQs = [...faq]; updatedFAQs.splice(i, 1); setFAQ(updatedFAQs); }} class="bi bi-trash purple-color cursor-pointer"></i>
                    </div>
                  </div>))}

                <div className="text-center">
                  <button onClick={() => handleSave("yes")} class="btn btn-lg wf-pink-btn wf-btn fw-bold text-uppercase py-2">{t('Save')}</button>
                </div>

              </div>

              {/* For Add */}
              {isBoxVisible && type == "add" && (<div className="wf-shadow-2 white-bg p-4 rounded mb-4">
                <div className="row mb-4">
                  <div className="col-md-6 ">
                    <h2 className="font-size-20 dm-font color-black mb-2">{t('Add')} {t('your')} {t('FAQ')}</h2>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <button type="button" onClick={() => setIsBoxVisible(false)} class="btn wf-btn wf-pink-btn px-4 fw-bold text-uppercase py-2">{t('Cancel')}</button>
                  </div>
                </div>
                <div class="form-floating wf-input wf-input-101 mb-3">
                  <input value={newfaq?.question} onChange={(e) => setNewFAQ({ ...newfaq, question: e.target.value })} type="text" class="form-control" id="question" placeholder="question" />
                  <label for="question" className="text-uppercase">{t('Question')}</label>
                </div>
                <div class="form-floating wf-textarea wf-textarea-101 mb-3">
                  <textarea value={newfaq?.answer} onChange={(e) => setNewFAQ({ ...newfaq, answer: e.target.value })} class="form-control" placeholder="answer" id="answer" style={{ "height": "200px" }} ></textarea>
                  <label for="answer" className="text-uppercase">{t('Answer')}</label>
                </div>
                <div className="text-center">
                  <button onClick={() => handleSave("no")} class="btn btn-lg wf-pink-btn wf-btn fw-bold text-uppercase py-2">{t('Add')} {t('FAQ')}</button>
                </div>
              </div>)}

              {/* For Edit */}
              {isBoxVisible && type == "edit" && (<div className="wf-shadow-2 white-bg p-4 rounded mb-4">
                <div className="row mb-4">
                  <div className="col-md-6">
                    <h2 className="font-size-20 dm-font color-black mb-2">{t('Update')} {t('your')} {t('FAQ')}</h2>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <button type="button" onClick={() => setIsBoxVisible(false)} class="btn wf-btn wf-pink-btn px-4 fw-bold text-uppercase py-2">{t('Cancel')}</button>
                  </div>
                </div>
                <div class="form-floating wf-input wf-input-101 mb-3">
                  <input value={newfaq?.question || ""}
                    onChange={(e) => {
                      setNewFAQ({ ...newfaq, question: e.target.value });
                    }}
                    type="text" class="form-control" id="question" placeholder="question" />
                  <label for="question" className="text-uppercase">{t('Question')}</label>
                </div>
                <div class="form-floating wf-textarea wf-textarea-101 mb-3">
                  <textarea value={newfaq?.answer || ""}
                    onChange={(e) => {
                      setNewFAQ({ ...newfaq, answer: e.target.value });
                    }} class="form-control" placeholder="answer" id="answer" style={{ "height": "200px" }} ></textarea>
                  <label for="answer" className="text-uppercase">{t('Answer')}</label>
                </div>
                <div className="text-center">
                  <button onClick={() => handleSave("no")} class="btn btn-lg wf-pink-btn wf-btn fw-bold text-uppercase py-2">{t('Update')} {t('FAQ')}</button>
                </div>
              </div>)}

            </section>
          </div>
        </div>
      </section>

    </>
  );
};

export default FAQ;