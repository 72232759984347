import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Layout/SidebarVendor";

export const Reviewsnew = () => {
    return (
        <>

            <section className="wf-vendor-business-details">
                <div className="container-fluid">
                    <div className="d-flex position-relative">
                        <Sidebar />
                        <section class="home-section">

                            <h2 className="font-size-30 dm-font purple-color mb-4 fw-normal">Reviews</h2>
                            <div className='row align-items-center gy-2 mb-4'>
                                <div className='col-6 col-lg-3'>
                                    <div class="wf-Totaltask-update">
                                        <h3 class="font-size-16 fw-normal color-grey mb-0">Total</h3>
                                        <p class="font-size-16 fw-bold purple-color mb-0">49 Reviews</p>
                                    </div>
                                </div>
                                <div className='col-6 col-lg-3'>
                                    <div class="wf-Totaltask-update">
                                        <h3 class="font-size-16 fw-bold purple-color mb-1">Overall Ratings</h3>
                                        <p class="font-size-18 fw-bold pink-color mb-0">
                                            <i class="bi bi-star-fill"></i>
                                            <i class="bi bi-star-fill"></i>
                                            <i class="bi bi-star-fill"></i>
                                            <i class="bi bi-star-fill"></i>
                                            <i class="bi bi-star-half"></i>
                                            <span className="ms-2">4.6</span></p>
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-6 text-end'>
                                    <button class="btn wf-btn wf-pink-btn px-4 fw-bold py-2 text-uppercase">RequestReviews</button>
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className='col-12'>
                                    <div className='wf-shadow-2 white-bg rounded px-3 py-3 '>
                                        <div className='d-flex flex-column flex-lg-row flex-wrap justify-content-between align-items-lg-center gap-3'>
                                            <div className='d-flex align-items-center gap-3'>
                                                <div className='wf-up-avatar d-flex mb-2'>
                                                    <div className='avatar-couple-first'>
                                                        <img width="57" height="57" alt="" src="https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg" class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                                                    </div>
                                                    <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                                                        <img width="57" height="57" alt="" src="https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg" class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                                                    </div>
                                                </div>
                                                <div className="mb-0">
                                                    <h3 className="font-size-18 fw-bold color-black mb-1">
                                                        <span className='purple-color'>Salman</span> & <span className='pink-color'> Hadeel</span>
                                                    </h3>
                                                    <p className='font-size-14 fw-medium color-black mb-0'><i class="bi bi-geo-alt-fill pink-color me-1"></i>Dubai, United Arab Emirates</p>
                                                </div>
                                            </div>
                                            <div className='text-end d-flex justify-content-between gap-4'>
                                                <div className='purple-color text-start'>
                                                    <h3 class="font-size-16 fw-bold purple-color mb-1">Ratings</h3>
                                                    <p class="font-size-18 fw-bold pink-color mb-0">
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <span className="ms-2">5.0</span>
                                                    </p>
                                                </div>
                                                <div className='wf-taskduedate'>
                                                    <h3 className="font-size-16 fw-bold purple-color mb-1">Date</h3>
                                                    <p className="font-size-16 fw-normal purple-color mb-0">12 Apr 2024</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className='col-12'>
                                    <div className='wf-shadow-2 white-bg rounded px-3 py-3 '>
                                        <div className='d-flex flex-column flex-lg-row flex-wrap justify-content-between align-items-lg-center gap-3'>
                                            <div className='d-flex align-items-center gap-3'>
                                                <div className='wf-up-avatar d-flex mb-2'>
                                                    <div className='avatar-couple-first'>
                                                        <img width="57" height="57" alt="" src="https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg" class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                                                    </div>
                                                    <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                                                        <img width="57" height="57" alt="" src="https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg" class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                                                    </div>
                                                </div>
                                                <div className="mb-0">
                                                    <h3 className="font-size-18 fw-bold color-black mb-1">
                                                        <span className='purple-color'>Salman</span> & <span className='pink-color'> Hadeel</span>
                                                    </h3>
                                                    <p className='font-size-14 fw-medium color-black mb-0'><i class="bi bi-geo-alt-fill pink-color me-1"></i>Dubai, United Arab Emirates</p>
                                                </div>
                                            </div>
                                            <div className='text-end d-flex justify-content-between gap-4'>
                                                <div className='purple-color text-start'>
                                                    <h3 class="font-size-16 fw-bold purple-color mb-1">Ratings</h3>
                                                    <p class="font-size-18 fw-bold pink-color mb-0">
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill"></i>
                                                        <span className="ms-2">5.0</span>
                                                    </p>
                                                </div>
                                                <div className='wf-taskduedate'>
                                                    <h3 className="font-size-16 fw-bold purple-color mb-1">Date</h3>
                                                    <p className="font-size-16 fw-normal purple-color mb-0">12 Apr 2024</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <p className="font-size-16 fw-normal color-black">From the beginning of planning to execution, we were greatly helped, starting from the venue visit and planning, the selection of details that we did not even think of.   From the beginning of planning to execution, we were greatly helped, starting from the venue visit and planning, the selection of details that we did not even think of.
                                                Thank you guys! :)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </section>

        </>
    )
}
