import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventList,editStoreFront,getAllStores
} from "../../store/slices/userSlice";
import Sidebar from "../../components/Layout/SidebarVendor";
import StoreNavBar from './StoreNavBar'
import { useTranslation } from "react-i18next";


const SuggestedVendors = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myEvents = useSelector(getEventList);
  const navigate = useNavigate();
  const location = useLocation();


  const [allvendors, setallvendors] = useState([])
  const [suggested_vendors, setsuggested_vendors] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);


  const getSuggestedVendors = async () => {
    try {
      setallvendors(null);
      const response = await dispatch(getAllStores()).unwrap();
      console.log("responsessss", response?.data);
  
      if (myEvents?._id) {
        const filteredData = response?.data.filter(
          (vendor) => vendor._id.toString() !== myEvents?._id?.toString()
        );
        setallvendors(filteredData);
      } else {
        setallvendors(response?.data);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  

  const removeVendorById = (_id) => {
    setsuggested_vendors((prevVendors) =>
      prevVendors.filter((vendor) => vendor._id !== _id)
    );
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleOptionClick = (option) => {
    setSearchValue("");
    setsuggested_vendors([...suggested_vendors,{_id: option?._id, store_name: option?.store_name}])
    setShowDropdown(false);
  };



  useEffect(() => {

    getSuggestedVendors();
 
     
  }, []);

  useEffect(() => {

if(myEvents){
    setsuggested_vendors(myEvents?.suggested_vendors)
     }
  }, [myEvents]);

  const handleSave = async (e) => {
    e.preventDefault();



    try {

        const requestData = {
          _id: myEvents?._id,
          path: location.pathname,
          suggested_vendors: JSON.stringify(suggested_vendors)
          };
        
       const nav= await dispatch(
          editStoreFront(requestData)
          ).unwrap();
          
       if(nav){
        navigate("/shadi_tayari-badge")
       }   
       
      
      
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };



  return (
    <>
    <section className="wf-vendor-contact-details">
        <div className="container-fluid">
        <div className="d-flex position-relative">  
    <Sidebar />
  <section class="home-section">
  {/* <StoreNavBar /> */}
    <div className="wf-shadow-2 white-bg p-4 rounded">
    <div className="d-flex gap-3 align-items-center mb-4">
        <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/preferred-suppliers-icon.svg`} alt="" className="events-icon" />
        <h2 className="font-size-36 playfair-font color-black mb-0">{t('PreferredSuppliers')}</h2>
    </div>
    
 
    <div className="dropdown wf-suggested-vendor-dropdown mb-3">
    <div class="form-floating wf-input wf-input-101">
      <input value={searchValue} onChange={handleInputChange} type="text" class="form-control" id="suggested-vendors" placeholder="name"
         autocomplete="name" required />
      <label for="suggested-vendors" className="text-uppercase">{t('SuggestedVendors')}</label>
    </div>
    {showDropdown && (
      <div className="dropdown-menu show" style={{ width: '100%' }}>
        <div className="list-autocomplete">
          {allvendors
            ?.filter((option) =>
              option.store_name.toLowerCase().includes(searchValue.toLowerCase())
            )
            .map((option, index) => (
              <button
                key={index}
                type="button"
                className="dropdown-item"
                onClick={() => handleOptionClick(option)}
              >
                {option.store_name}
              </button>
            ))}
         {allvendors?.length > 0 &&
          allvendors
            ?.filter((option) =>
              option.store_name.toLowerCase().includes(searchValue.toLowerCase())
            ).length < 1 && (
          <i className="hasNoResults">No matching results</i>
        )}

        </div>
      </div>
    )}
  </div>
  
     
    
  {suggested_vendors?.map((s)=>(
  <div className="mb-2">
        <p className="font-size-18 color-black mb-0"><i onClick={() => removeVendorById(s?._id)} className="bi bi-x-circle cursor-pointer pink-color"></i> {s?.store_name}</p>
      </div>))}
      
    <div className="text-end mt-3">
      <button onClick={handleSave} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2" >{t('Save')}</button>
    </div>
    </div>
  </section>
        </div>
        </div>
  </section>

    </>
  );
};

export default SuggestedVendors;