import React  from 'react';
import ReactDOM from 'react-dom/client';
 import App from './App';
import { Provider } from 'react-redux';
import Store from "./store"
import { Toaster } from 'react-hot-toast';
import { I18nextProvider } from 'react-i18next';
import i18n from './components/i18n';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={Store}>
    <I18nextProvider i18n={i18n}>
      <App />
      <Toaster
        position='top-center'
        reverseOrder={false}
        toastOptions={{
          duration: 1500
        }}

      />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
); 


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
