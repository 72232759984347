import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"
import {
  getEventList, getAllBookings
} from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Layout/SidebarVendor";
import { useTranslation } from "react-i18next";
import StoreNavBar from "./StoreNavBar";

const Bookings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myEvents = useSelector(getEventList);


  const [bookings, setbookings] = useState([])
  const [load, setload] = useState(false)
  // const [filter, setfilter] = useState("recentlyAdded");




  const Details = async () => {
    try {
      setload(true)
      setbookings([])
      const response = await dispatch(getAllBookings()).unwrap();
      console.log('response.data22', response.data)
      setbookings(response?.data)
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
     }
  };


  useEffect(() => {
    if (myEvents) {
      Details();
    }
  }, [myEvents]);

  console.log('bookings', bookings)


  return (
    <>
      <section className="wf-vendor-bookings">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section pt-0">
            <h2 className="font-size-36 purple-color mb-2 dm-font">{t('Bookings')}</h2>
              <div className="">
            
              {load ? new Array(4).fill(null).map((_, index) => (<>
              <div style={{height:'150px'}} className="shimmer w-100 rounded mb-3"></div>
              </>)) : bookings?.map((data, b) => (<>
              <div key={b} className="wf-task-group-list wf-shadow-2 white-bg p-3 rounded mb-3">
              <div className="d-flex justify-content-between">
              <div className="col-md-2 align-self-center">
                  <div class="d-flex wf-booking-couple justify-content-center">
                    <img style={{border:'3px solid var(--color3)'}} class="rounded-circle profile-1" alt="avatar" src={data?.user_event?.user_id?.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${data?.user_event?.user_id?.person1?.imageName}` : "https://via.placeholder.com/50x50"} />
                    <img style={{marginLeft:'-30px', border:'3px solid var(--color4)'}} alt="avatar" src={data?.user_event?.user_id?.person2?.imageName ? `${process.env.REACT_APP_IMGURL}${data?.user_event?.user_id?.person2?.imageName}` : "https://via.placeholder.com/50x50"} class="rounded-circle profile-2" />
                  </div>
                </div>
                <div className="col-md-2 align-self-center">
                <p className="font-size-16 color-black mb-0 fw-semibold"><span className="purple-color">{data?.user_event?.user_id?.person1?.name}</span> & <span className="pink-color">{data?.user_event?.user_id?.person2?.name||data?.user_event?.user_id?.person1?.user_purpose=="groom"?"bride":"groom"}</span></p>
                <p className="font-size-16 color-black mb-0 fw-medium">{data?.user_event?.event_city?.name}, {data?.user_event?.event_city?.country_id?.name}</p>
                </div>
                <div className="col-md-6 align-self-center">
                <div className="d-flex align-items-center justify-content-between">
                <p className="font-size-16 mb-1 text-capitalize d-flex flex-column align-items-start mb-0">
                {data?.user_event?.event_name}
                  <span className="d-block mt-1" style={{ backgroundColor: `${data?.user_event?.event_color}`, height:'3px', width:'60px' }}></span>
                </p>
                <div className="col-md-10">
                    <div className="w-100">
                    <div className="d-flex justify-content-between align-items-center">
                      <p
                        style={{ lineHeight: "normal" }}
                        className="font-size-14 color-grey mb-1 text-start"
                      >
                        <b>{t('Paid')}</b> <br /> SAR{" "}
                        {data?.paid||0}
                      </p>
                      <p
                        style={{ lineHeight: "normal" }}
                        className="font-size-14 pink-color mb-1 text-end"
                      >
                        <b>{t('Due')}</b> <br /> SAR{" "}
                        {data?.due||0}{" "}
                      </p>
                    </div>
                    <div
                      class="progress"
                      style={{ height: "12px", width: "100%" }}
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Example 20px high"
                        style={{
                          width: b.offer_id?`${
                            (data?.paid /
                            data?.totalPrice) *
                            100
                          }%`:`${
                            (data?.paid /
                            data?.totalPrice) *
                            100
                          }%`,
                          backgroundColor: "#FF74B8",
                        }}
                        aria-valuenow={b.offer_id?`${
                          (b.offer_id.price.paymentRecieved /
                          b.offer_id.price.totalPrice) *
                          100
                        }%`:`${
                          (b?.initialDeposit /
                          b?.totalPrice) *
                          100
                        }%`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                
              </div>
                </div>
                <div className="col-md-2">
                <div className="d-flex gap-2 justify-content-end align-items-center mb-1">
                    <div className="d-flex gap-2">
                      <p className="font-size-16 purple-color mb-0">{data?.status == 2 ? "Pending" : data?.status == 0 ? "Cancelled" : data?.status == 1 ? "Confirmed" : data?.status == 3 ? "Completed" : null}</p>
                      <span className="btn wf-btn-checkk active"><i class="bi bi-check-lg d-flex"></i></span>
                    </div>
                    <div class="dropdown wf-list-dropdown">
                    {/* <a class="color-grey font-size-22" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots-vertical"></i>
                    </a> */}

                    <div onClick={() => navigate("/booking-details",{state:{data: data}})} className='color-grey cursor-pointer'>
                            <i class="bi bi-chevron-right"></i>
                          </div>
                    {/* <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Conversations</a></li>
                      <li><a class="dropdown-item" onClick={()=>navigate("/booking-details",{state:{data: data}})}>Details</a></li>
                      <li><a class="dropdown-item" href="#">Paid</a></li>
                    </ul> */}
                  </div>
                    </div>
                    <div>
                <p className="font-size-12 color-black mb-0 text-end">Due Date</p>
                <p className="font-size-14 purple-color mb-1 fw-semibold text-uppercase text-end">
                {moment(data?.user_event?.event_date).format("DD MMM YYYY")}
                  </p>
                </div>
                </div>
              </div>
            </div>
                </>))}
              </div>
            </section>
          </div>
        </div>
      </section>

    </>
  )
}

export default Bookings