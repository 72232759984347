import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventList, editStoreFront
} from "../../store/slices/userSlice";
import Sidebar from "../../components/Layout/SidebarVendor";
import StoreNavBar from './StoreNavBar'
import { useTranslation } from "react-i18next";

const SocialNetwork = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const myEvents = useSelector(getEventList);
  const navigate = useNavigate();
  const location = useLocation();



  const [facebook, setfacebook] = useState("");
  const [twitter, settwitter] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [instagram, setinstagram] = useState("");
  const [youtube, setyoutube] = useState("");
  const [pinterest, setpinterest] = useState("")


  const handleSave = async (e) => {
    e.preventDefault();



    try {

      const requestData = {
        _id: myEvents?._id,
        path: location.pathname,
        facebook: facebook,
        twitter: twitter,
        linkedin: linkedin,
        instagram: instagram,
        youtube: youtube,
        pinterest: pinterest
      };

      const nav = await dispatch(
        editStoreFront(requestData)
      ).unwrap();

      if (nav) {
        navigate("/preferred-suppliers")
      }



    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {


    if (myEvents) {

      setfacebook(myEvents?.facebook == "null" ? "" : myEvents?.facebook);
      settwitter(myEvents?.twitter == "null" ? "" : myEvents?.twitter);
      setlinkedin(myEvents?.linkedin == "null" ? "" : myEvents?.linkedin);
      setinstagram(myEvents?.instagram == "null" ? "" : myEvents?.instagram);
      setyoutube(myEvents?.youtube == "null" ? "" : myEvents?.youtube);
      setpinterest(myEvents?.pinterest == "null" ? "" : myEvents?.pinterest);
    }


  }, [myEvents]);


  return (
    <>
      <section className="wf-vendor-contact-details">
        <div className="container-fluid">
          <div className="d-flex position-relative">
            <Sidebar />
            <section class="home-section">
              {/* <StoreNavBar /> */}
              {/* <div className="wf-shadow-2 white-bg p-4 rounded">
                <div className="d-flex gap-3 align-items-center mb-4">
                  <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/social-network-icon.svg`} alt="" className="events-icon" />
                  <h2 className="font-size-36 playfair-font color-black mb-0">{t('SocialNetwork')}</h2>
                </div>
                <div class="input-group wf-input-group wf-shadow-2-2 mb-3">
                  <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/facebook-logo.svg`} alt="" /></span>
                  <div class="form-floating wf-input">
                    <input defaultValue={facebook && facebook} onChange={(e) => setfacebook(e.target.value)} type="text" class="form-control" id="facebook" placeholder="Facebook" required />
                    <label for="facebook">Facebook</label>
                  </div>
                </div>

                <div class="input-group wf-input-group wf-shadow-2-2 mb-3">
                  <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/x-logo.svg`} alt="" /></span>
                  <div class="form-floating wf-input">
                    <input defaultValue={twitter && twitter} onChange={(e) => settwitter(e.target.value)} type="text" class="form-control" id="twitter" placeholder="twitter" required />
                    <label for="twitter">X</label>
                  </div>
                </div>

                <div class="input-group wf-input-group wf-shadow-2-2 mb-3">
                  <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/linkedin-logo.svg`} alt="" /></span>
                  <div class="form-floating wf-input">
                    <input defaultValue={linkedin && linkedin} onChange={(e) => setlinkedin(e.target.value)} type="text" class="form-control" id="linkedIn" placeholder="LinkedIn" required />
                    <label for="linkedIn">LinkedIn</label>
                  </div>
                </div>

                <div class="input-group wf-input-group wf-shadow-2-2 mb-3">
                  <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/insta-logo.svg`} alt="" /></span>
                  <div class="form-floating wf-input">
                    <input defaultValue={instagram && instagram} onChange={(e) => setinstagram(e.target.value)} type="text" class="form-control" id="instagram" placeholder="Instagram" required />
                    <label for="instagram">Instagram</label>
                  </div>
                </div>

                <div class="input-group wf-input-group wf-shadow-2-2 mb-3">
                  <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/youtube-logo.svg`} alt="" /></span>
                  <div class="form-floating wf-input">
                    <input defaultValue={youtube && youtube} onChange={(e) => setyoutube(e.target.value)} type="text" class="form-control" id="youtube" placeholder="Youtube" required />
                    <label for="youtube">Youtube</label>
                  </div>
                </div>

                <div class="input-group wf-input-group wf-shadow-2-2 mb-4">
                  <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/pinterest-logo.svg`} alt="" /></span>
                  <div class="form-floating wf-input">
                    <input defaultValue={pinterest && pinterest} onChange={(e) => setpinterest(e.target.value)} type="text" class="form-control" id="pinterest" placeholder="Pinterest" required />
                    <label for="pinterest">Pinterest</label>
                  </div>
                </div>

                <div className="text-end">
                  <button onClick={handleSave} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">{t('Save')}</button>
                </div>
              </div> */}

              <div className="wf-shadow-2 white-bg p-4 rounded">
                <div className="d-flex gap-3 align-items-center mb-4">
                  {/* <img height="36" src={`${process.env.REACT_APP_FrontURL}/assets/images/social-network-icon.svg`} alt="" className="events-icon" /> */}
                  <h2 className="font-size-20 dm-font color-black mb-3">{t('SocialNetwork')}</h2>
                </div>
                <div className="row g-4 mb-4">
                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/facebook-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={facebook && facebook} onChange={(e) => setfacebook(e.target.value)} type="text" class="form-control" id="facebook" placeholder="Facebook" required />
                        <label for="facebook">Facebook</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/x-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={twitter && twitter} onChange={(e) => settwitter(e.target.value)} type="text" class="form-control" id="twitter" placeholder="twitter" required />
                        <label for="twitter">X</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/linkedin-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={linkedin && linkedin} onChange={(e) => setlinkedin(e.target.value)} type="text" class="form-control" id="linkedIn" placeholder="LinkedIn" required />
                        <label for="linkedIn">LinkedIn</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/insta-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={instagram && instagram} onChange={(e) => setinstagram(e.target.value)} type="text" class="form-control" id="instagram" placeholder="Instagram" required />
                        <label for="instagram">Instagram</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/youtube-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={youtube && youtube} onChange={(e) => setyoutube(e.target.value)} type="text" class="form-control" id="youtube" placeholder="Youtube" required />
                        <label for="youtube">Youtube</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-md-12">
                    <div class="d-flex align-items-center wf-input-group wf-shadow-2-2">
                      <span class="input-group-text"><img height="20" src={`${process.env.REACT_APP_FrontURL}/assets/images/pinterest-logo.svg`} alt="" /></span>
                      <div class="form-floating wf-input w-100">
                        <input defaultValue={pinterest && pinterest} onChange={(e) => setpinterest(e.target.value)} type="text" class="form-control" id="pinterest" placeholder="Pinterest" required />
                        <label for="pinterest">Pinterest</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-end">
                  <button onClick={handleSave} class="btn btn-lg wf-pink-btn wf-btn fw-bold text-uppercase py-2">{t('Save')}</button>
                </div>
              </div>

            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default SocialNetwork